import React, { useState } from 'react';
import { Row, Col, Button, Modal } from 'antd';

import Desc from '../../Desc';

import { generateSecret, SecretProps } from '../../../../developer/api/application'

import styles from './index.module.scss';

interface KeyProps {
  value?: string;
  name: string;
  id?: string;
}

const Key: React.FC<KeyProps> = (props) => {
  const { value, name, id } = props;

  const [secret, setSecret] = useState<SecretProps>();
  // const [show, setShow] = useState<boolean>(false);

  const Create = () => {
    if (id) {
      generateSecret(id).then(res => {
        setSecret(res);
      })
    }

  }

  const onClick = () => {
    Modal.confirm({
      title: <div className={styles.title}>你确定要生成密钥吗？</div>,
      // icon: <ExclamationCircleOutlined />,
      content: '如果已经生成过密钥，再次生成密钥会导致之前的项目不可用。',
      okText: '确认',
      cancelText: '取消',
      onOk: Create
    });
  }
  return (
    <>
      <Row gutter={40}>
        <Col span={12}>
          <span className={styles.name}>{name}：</span>
          <span>{value}</span>
        </Col>
        <Col span={12}>
          <span className={styles.name}>密钥：</span>
          <Button type="link" onClick={onClick}>点击生成</Button>
          <Desc>出于安全考虑，密钥不会保存请谨慎操作</Desc>
        </Col>
      </Row>
      <Modal
        title={<div className={styles.title}>密钥<Desc>请保存好您的密钥，密钥不会再次显示</Desc></div>}
        visible={!!secret}
        onCancel={() => {setSecret(undefined)}}
        footer={
          <div className={styles.modalFooter}>
            <Button onClick={() => {setSecret(undefined)}}>关闭</Button>
          </div>
        }
      >
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.key}>应用ID:</div>
            <div>{secret!?.appKey}</div>
          </div>
          <div className={styles.content}>
            <div className={styles.key}>密钥:</div>
            <div>{secret!?.appSecret}</div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Key;
