import React, { useMemo, Key, useState, useCallback } from 'react';
import { Button } from 'antd';

interface ShowProps {
  value?: Key | Key[];
  buildValue?: (data: Key[] | Key) => string[];
}

const formatValue = (data: Key | Key[]) => {
  if (Array.isArray(data)) {
    return data;
  }
  return [data];
}

const Show: React.FC<ShowProps> = (props) => {
  const { value, buildValue } = props;
  const [all, setAll] = useState<boolean>(false);

  const hasShowAll = useMemo(() => {
    return Array.isArray(value);
  }, [value]);

  const showValue = useMemo(() => {
    if (!value) return [];
    if (buildValue) {
      return formatValue(buildValue(value))
    } else {
      return formatValue(value)
    }
  }, [value, buildValue])

  const buildString = useCallback((data: Key[]) => {
    return data.join('，')
  }, [])

  const showString = useMemo(() => {
    return buildString(showValue);
  }, [buildString, showValue]);

  return (
    <span style={{color: 'rgba(0,0,0,0.65)'}}>
      {
        hasShowAll ? (
          showValue.length > 5 ? (
            all ? (
              showString
            ) : (
              <div style={{display: 'flex', alignItems: 'center'}}>
                {buildString(showValue.slice(0, 5))}
                <Button type="link" onClick={() => {setAll(true)}}>更多</Button>
              </div>
            )
          ) : (
            showString
          )
        ) : (
          showString
        )
      }
    </span>
  )
}

export default Show;