import React, { } from "react";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import { Tabs } from "antd";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import JsonTreeContent from "../json-tree/JsonTreeContent";
import { ResponseParam, ResponseParamHeader } from "../../index";

interface ResponseTableProps {
  data: ResponseParam[];
}

interface HeaderTableProps {
  headers: ResponseParamHeader[];
}

const { TabPane } = Tabs;
const cx = classNames.bind(styles);

const HeaderTable: React.FC<HeaderTableProps> = ({ headers }) => {
  return (
    <>
      <p>Headers</p>
      <table className={cx("headers")}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {headers.map((header) => {
            const { name, description, schema } = header;
            return (
              <tr key={name}>
                <td>{name}</td>
                <td>
                  <div className="markdown">
                    <p>{description}</p>
                  </div>
                </td>
                <td>
                  {schema ? schema.type : undefined}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const ResponseTable: React.FC<ResponseTableProps> = ({ data }) => {
  return (
    <div className={cx("common-table-wrapper")}>
      <table>
        <thead>
          <tr>
            <th className={cx("col-header", "col-name")}>Name</th>
            <th className={cx("col-header", "col-description")}>Description</th>
          </tr>
        </thead>
        <tbody>
          {data.map((param) => {
            const {
              name,
              type,
              enum: _enum,
              description,
              schemaTree,
              example,
              headers
            } = param;
            return (
              <tr key={param.name}>
                <td className={cx("col-name")}>
                  <div className={cx("name")}>{name}</div>
                  <div className={cx("deprecated")}></div>
                </td>
                <td className={cx("col-description")}>
                  <div className={cx("markdown")}>
                    <p>{description}</p>
                  </div>
                  {Array.isArray(_enum) && _enum.length > 0 ? (
                    <div className={cx("enum", "markdown")}>
                      <p>
                        <i>Available values</i> : _enum.join(',')
                      </p>
                    </div>
                  ) : undefined}
                  {schemaTree ? (
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Example Value" key="1">
                        {example ? (
                          <SyntaxHighlighter language="json" style={atomDark}>
                            {example}
                          </SyntaxHighlighter>
                        ) : undefined}
                      </TabPane>
                      <TabPane tab="Model" key="2">
                        <div className={cx("json-tree-wrapper")}>
                          <JsonTreeContent
                            data={schemaTree}
                            format="text"
                          />
                        </div>
                      </TabPane>
                    </Tabs>
                  ) : undefined}
                  {Array.isArray(headers) && headers.length > 0 ? <HeaderTable headers={headers}/> : undefined}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ResponseTable;

