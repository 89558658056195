import React, {PropsWithChildren} from "react"
import {Header, PlatformType} from "./Header";
import {useSSOAuth} from "@maxtropy/central-commons-ui";

interface OperationLayoutProps {

}

const OPERATION_MENU_DATA = [
  {name: "首页", url: "/operator", key: 'home'},
  {name: "开发者管理", url: "/operator/manage", key: 'manage'},
  {name: "应用查询", url: "/operator/application", key: 'application'},
];

const OperatorLayout: React.FC<PropsWithChildren<OperationLayoutProps>> = ({children}) => {
  const authState = useSSOAuth(true, true);

  return (
    <div key={authState}>
      <Header type={PlatformType.OPERATION} menuData={OPERATION_MENU_DATA}/>
      {children}
    </div>
  )
};

export default OperatorLayout;
