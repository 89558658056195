import { Button, Col, Row, Space, DatePicker, Tooltip, message } from "antd";
import classNames from "classnames";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  AggrByType,
  getInvocationsTop,
  getInvocationsTrend,
  getStatsBrief,
  Invocation,
  InvocationByTime,
  StatsBrief,
  SystemStatusType,
} from "../../developer/api/stats";
import styles from "./index.module.scss";

const { RangePicker } = DatePicker;

const numberFormatter = new Intl.NumberFormat();

type RangeType = "year" | "day" | "month" | "isoWeek";

const detectedRangeType = (dateRange: [moment.Moment, moment.Moment]) => {
  for (const rangeType of ["day", "isoWeek", "year", "month"] as RangeType[]) {
    if (
      moment().startOf(rangeType).isSame(dateRange[0], "days") &&
      moment().isSame(dateRange[1], "day")
    ) {
      return rangeType;
    }
  }
};

const Operation: React.FC = () => {
  const [dateRange, setDateRange] = useState<[moment.Moment, moment.Moment]>([
    moment().startOf("month"),
    moment(),
  ]);
  const [selectedRangeType, setSelectedRangeType] = useState<
    RangeType | undefined
  >("month");

  const [aggrBy, setAggrBy] = useState(AggrByType.DAY);
  const [stats, setStats] = useState<StatsBrief>();
  const [top, setTop] = useState<Invocation[]>();
  const [trend, setTrend] = useState<InvocationByTime[]>();

  const onRangeCick = (type: RangeType) => {
    setSelectedRangeType(type);
    setDateRange([moment().startOf(type), moment()]);
  };

  useEffect(() => {
    if (!detectedRangeType(dateRange)) {
      setSelectedRangeType(undefined);
    }

    const [start, end] = dateRange;
    const diff = end.diff(start, "days");
    const aggrBy =
      diff === 0
        ? AggrByType.HOUR
        : diff < 31
        ? AggrByType.DAY
        : AggrByType.MONTH;

    const endTime = end.format("yyyy-MM-DD");
    const startTime = start.format("yyyy-MM-DD");

    setAggrBy(aggrBy);
    setTrend(undefined);
    setTop(undefined);

    getInvocationsTrend({
      start: startTime,
      end: endTime,
      aggrBy,
    }).then((res) => {
      if (res) {
        setTrend(res);
      }
    });
    getInvocationsTop({
      start: startTime,
      end: endTime,
    }).then((res) => {
      if (res) {
        setTop(res);
      }
    });
  }, [dateRange]);

  useEffect(() => {
    getStatsBrief().then((res) => {
      if (res) {
        setStats(res);
      }
    });
  }, []);

  return (
    <div className={styles.container}>
      <header>
        <div className={styles.box}>
          <div className={styles.boxTitle}>应用数量</div>
          <div className={styles.boxValue}>
            {stats && numberFormatter.format(stats.applicationCount)}
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.boxTitle}>接口数量</div>
          <div className={styles.boxValue}>
            {stats && numberFormatter.format(stats.endpointCount)}
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.boxTitle}>开发者数量</div>
          <div className={styles.boxValue}>
            {stats && numberFormatter.format(stats.developerCount)}
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.boxTitle}>累计调用接口次数</div>
          <div className={styles.boxValue}>
            {stats && numberFormatter.format(stats.invocationCount)}
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.boxTitle}>系统运行状态</div>
          <Tooltip title={stats && stats.systemStatusDescription}>
            <span
              className={classNames(
                styles.boxStatus,
                stats &&
                  stats.systemStatus === SystemStatusType.ABNORMAL &&
                  styles.abnormal
              )}
            >
              {stats && stats.systemStatus === SystemStatusType.ABNORMAL
                ? "异常"
                : "正常"}
            </span>
          </Tooltip>
        </div>
      </header>

      <div className={styles.bd}>
        <div className={styles.left}>
          <div className={styles.title}>接口统计</div>
          <div className={styles.subTitle}>接口调用次数趋势</div>
          <ReactEcharts
            style={{ height: "calc(100% - 50px)" }}
            option={option(aggrBy, dateRange, trend)}
          />
        </div>
        <div className={styles.right}>
          <Space>
            <Button
              type="text"
              className={classNames(
                selectedRangeType === "day" && styles.selected
              )}
              onClick={() => onRangeCick("day")}
            >
              今日
            </Button>
            <Button
              type="text"
              className={classNames(
                selectedRangeType === "isoWeek" && styles.selected
              )}
              onClick={() => onRangeCick("isoWeek")}
            >
              本周
            </Button>
            <Button
              type="text"
              className={classNames(
                selectedRangeType === "month" && styles.selected
              )}
              onClick={() => onRangeCick("month")}
            >
              本月
            </Button>
            <Button
              type="text"
              className={classNames(
                selectedRangeType === "year" && styles.selected
              )}
              onClick={() => onRangeCick("year")}
            >
              全年
            </Button>
            <RangePicker
              allowClear={false}
              value={dateRange}
              onChange={(range) => {
                const [start, end] = range;
                if (end!.diff(start, "years") >= 1) {
                  return message.error("最大上限为1年");
                }
                setDateRange(range! as [moment.Moment, moment.Moment]);
              }}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </Space>
          <div className={styles.tableTitle}>调用次数统计排名</div>
          <div className={styles.table}>
            <Row align="middle" className={styles.tableHeader}>
              <Col offset={1} span={4}>
                排名
              </Col>
              <Col span={15}>接口名称</Col>
              <Col span={4}>次数</Col>
            </Row>
            {top &&
              top.map((item, index) => (
                <Row key={item.name} align="middle" className={styles.tableRow}>
                  <Col offset={1} span={4}>
                    <div
                      className={classNames(
                        styles.rank,
                        index < 3 && styles.topThree
                      )}
                    >
                      {index + 1}
                    </div>
                  </Col>
                  <Col span={15}>{item.name}</Col>
                  <Col span={4}>{item.count}</Col>
                </Row>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

function option(
  aggrBy: AggrByType,
  dateRange: [moment.Moment, moment.Moment],
  trend?: InvocationByTime[]
) {
  if (!trend) return {};

  const diffUnit =
    aggrBy === AggrByType.HOUR
      ? "hours"
      : aggrBy === AggrByType.DAY
      ? "days"
      : "months";
  const format =
    aggrBy === AggrByType.HOUR
      ? "H点"
      : aggrBy === AggrByType.DAY
      ? "yyyy-MM-DD"
      : "yyyy M月";
  const [start, end] = dateRange;

  let data = [...trend];

  if (aggrBy !== AggrByType.HOUR) {
    const diff = end.diff(start, diffUnit);
    data = Array.from(Array(diff + 1)).map((_, i) => {
      if (i < diff + 1 - trend.length) {
        return {
          time: start.clone().add(i, diffUnit).toISOString(),
          value: 0,
        };
      }
      return trend[i - (diff + 1 - trend.length)];
    });
  }

  return {
    color: ["#1890FF"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      top: 30,
      left: 0,
      right: 20,
      bottom: 30,
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: data.map((item) => moment(item.time).format(format)),
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          color: "#000000A6",
        },
        axisLine: {
          lineStyle: {
            color: "#D9D9D9",
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: "#000000A6",
        },
        splitLine: {
          lineStyle: {
            color: "#E8E8E8",
            type: "dashed",
          },
        },
      },
    ],
    series: [
      {
        name: "调用次数",
        type: "bar",
        barWidth: "50%",
        data: data.map((item) => item.value),
      },
    ],
  };
}

export default Operation;
