import React from 'react';
import { DataNode as AntdTreeDataNode } from 'antd/lib/tree';

import Show from '../Show';

import { DynamicFormItemProps } from '../../entity';
import BaseTreeSelect from './TreeSelect';


interface DataNode extends Omit<AntdTreeDataNode, 'title' | 'children'> {
  title: string;
  children?: DataNode[];
}
export interface TreeSelectProps extends Omit<DynamicFormItemProps, 'self_props' | 'data'> {
  data: DataNode[];
  selfProps: {
    hasSearch?: boolean;
    placeholder?: string;
    searchPlaceholder?: string;
  };
}

const buildValue = (data: DataNode[]) => {
  const getValue = (value: React.Key[] | React.Key) => {
    if (!Array.isArray(value)) {
      value = [value]
    }
    const res: string[] = [];
    data.forEach(i => {
      if (i.children && i.children.length) {
        const childGetValue = buildValue(i.children)
        res.push(...childGetValue(value))
      } else {
        if ((value as React.Key[]).includes(i.key)) {
          res.push(i.title)
        }
      }
    })
    return res;
  }
  return getValue;
}

// 对treeData变换，可能不需要
const buildTreeData = (data: any) => {
  return data;
}

const TreeSelect: React.FC<TreeSelectProps> = (props) => {
  const { data, selfProps: { hasSearch, placeholder = '', searchPlaceholder = '' }, hasShow = false, ...restProps } = props;
  

  return (
    hasShow ? (
      <Show buildValue={buildValue(data)} {...restProps} />
    ) : (
      <BaseTreeSelect
        {...restProps}
        treeData={buildTreeData(data)}
        // filterTreeNode={(e) => {console.log(e); return true;}}
        treeNodeFilterProp="title"
        hasSearch={hasSearch}
        treeCheckable={true}
        placeholder={placeholder}
        searchPlaceholder={searchPlaceholder}
      />
    )
  )
}

export default TreeSelect;