import React from 'react';
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import './App.scss';
import Operation from "./operator/pages";
import DeveloperLayout from "./open-platform-commons/components/layout/DeveloperLayout";
import OperatorLayout from "./open-platform-commons/components/layout/OperatorLayout";
import NotAuthorized from './open-platform-commons/components/NotAuthorized';

//开发者侧
import CreateApplication from './developer/pages/CreateApplication';
import ShowApplication from './developer/pages/ShowApplication';
import ConsoleList from "./developer/pages/console/ConsoleList";
import InterfaceMain from './developer/pages/InterfaceMain';

//运维侧
import ShowOperationApplication from './operator/pages/ShowApplication'
import ManageEdit from './operator/pages/manage/ManageEdit';
import ManageList from "./operator/pages/manage/ManageList";
import ManageDetail from "./operator/pages/manage/ManageDetail";
import Application from './operator/pages/Application';

const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/developer">
            <DeveloperLayout>
              <Switch>
                <Route path="/developer/document" exact>
                  <InterfaceMain/>
                </Route>
                <Route path="/developer" exact>
                  <ConsoleList/>
                </Route>
                <Route path="/developer/create/:appId?" exact>
                  <CreateApplication/>
                </Route>
                <Route path="/developer/show/:appId" exact>
                  <ShowApplication/>
                </Route>
              </Switch>
            </DeveloperLayout>
          </Route>
          <Route path="/operator">
            <OperatorLayout>
              <Switch>
                <Route path="/operator" exact>
                  <Operation/>
                </Route>
                <Route path="/operator/manage/edit/:mcid" exact>
                  <ManageEdit/>
                </Route>
                <Route path="/operator/manage" exact>
                  <ManageList/>
                </Route>
                <Route path="/operator/manage/detail/:mcid" exact>
                  <ManageDetail/>
                </Route>
                <Route path="/operator/application/:developerId?" exact>
                  <Application/>
                </Route>
                <Route path="/operator/application/:developerId/show/:appId" exact>
                  <ShowOperationApplication/>
                </Route>
              </Switch>
            </OperatorLayout>
          </Route>
          <Route path="/not-authorized">
            <NotAuthorized/>
          </Route>
          <Route path="/incomplete-customer">
            <NotAuthorized text="您无权访问该应用，请联系商务人员进行开通"/>
          </Route>
          <Route path="/" exact>
            {
              /^openplatform-mgmt\./i.test(window.location.hostname) ?
                <Redirect to="/operator"/> :
              /^openplatform\./i.test(window.location.hostname) ?
                <Redirect to="/developer"/> :
                <Redirect to="/not-authorized"/>
            }
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
