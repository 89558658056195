import React from 'react';
import { Form } from 'antd';

import { DynamicFormProps, DynamicFormItemProps, DynamicFormType } from './entity';

import Select from './module/Select';
import TreeSelect from './module/TreeSelect';
import Tree from './module/Tree';
import TabCheckbox from './module/TabCheckbox';

const buildDynamicItem = (props: DynamicFormItemProps) => {
  switch(props.type) {
    case DynamicFormType.SELECT:
      return <Select {...props}/>;
    case DynamicFormType.TREESELECT:
      return <TreeSelect {...props} />
    case DynamicFormType.TREE:
      return <Tree {...props} />
    case DynamicFormType.TABCHECKBOX:
      return <TabCheckbox {...props} />
  }
}

const DynamicForm: React.FC<DynamicFormProps & { className?: string; }> = (props) => {
  const {className, fieldName, label, rules, ...restProps} = props;
  return (
    <Form.Item label={!restProps.hasShow ? label : label.replace('(多选)', '')}
               name={fieldName} className={className}
               rules={!restProps.hasShow ? rules ?? [] : []}>
      {buildDynamicItem(restProps)}
    </Form.Item>
  )
}

export default DynamicForm;
