import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';

import styles from './index.module.scss';

const Desc: React.FC = (props) => {
  return (
    <span className={styles.desc}>
      <ExclamationCircleFilled className={styles.icon} />
      {props.children}
    </span>
  )
}

export default Desc;