import { Table, Tabs } from 'antd';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { generateExample, SchemaModel, schemaToModel } from './schema-utils';
import styles from './index.module.scss';
import ProcessSpec, {ProcessSpecResult, OperationObject} from "./swagger-parse";
import RequestTable from './components/table/RequestTable';
import ResponseTable from './components/table/ResponseTable';
import { OpenAPIV3 } from "./Entity";
import classNames from "classnames/bind";
import {generateTree, getTokens, strongMarked, TreeNode} from "./markdown-utils";
import { flowRight } from 'lodash';
import DocumentAnchor from "./components/anchor";
import {queryUpstreamServiceInfo, queryUpstreamServiceList, UpstreamServiceListItem} from "../../../open-platform-commons/api/upstreamService";

const cx = classNames.bind(styles);

export interface RequestParam {
  name: string;
  in: string;
  type?: string;
  required?: boolean;
  description?: string;
  schemaTree?: SchemaModel;
  example?: string;
  enum?: any[];
  format?: string;
}

export interface ResponseParamHeader extends OpenAPIV3.HeaderObject {
  name: string;
}

export interface ResponseParam {
  name: string;
  description?: string;
  schemaTree?: SchemaModel;
  example?: string;
  headers?: ResponseParamHeader[];
  type?: string;
  enum?: any[];
}

const { TabPane } = Tabs;

const useMarkdown = (md: string) => {
  const [markdownHtml, setMarkdownHtml] = useState<string>("");
  const [documentTree, setDocumentTree] = useState<TreeNode[]>([]);

  useEffect(() => {
    fetch(md).then(response => response.blob()).then(data => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        const markdownString = (event.target!.result) as string;
        setMarkdownHtml(strongMarked(markdownString));
        const tree = flowRight([generateTree, getTokens])(markdownString);
        setDocumentTree(tree);
      }
      fileReader.readAsText(data);
    })
  }, [])

  return {
    markdownHtml,
    documentTree
  }
}


const InterfaceMain: React.FC = () => {

  const {markdownHtml: scratchMarkdownHtml, documentTree: scratchDocumentTree} = useMarkdown("/md/scratch.md");
  const {markdownHtml: contentMarkdownHtml, documentTree: contentDocumentTree} = useMarkdown("/md/content.md");
  const [upstreamServiceList, setUpstreamServiceList] = useState<UpstreamServiceListItem[]>([]);

  const [interfaceBasePath, setInterfaceBasePath] = useState("");

  const markdownHtml = useMemo(() => {
    if(scratchMarkdownHtml && contentMarkdownHtml) {
      return contentMarkdownHtml + scratchMarkdownHtml;
    }
    return '';
  }, [scratchMarkdownHtml, contentMarkdownHtml])

  const documentTree = useMemo(() => {
    if(scratchDocumentTree.length && contentDocumentTree.length) {
      return [...contentDocumentTree, ...scratchDocumentTree];
    }
    return [];
  }, [scratchDocumentTree, contentDocumentTree])

  const [activeIdentifier, setActiveIdentifier] = useState<string>();

  const [activeKey, setActiveKey] = useState<string>("1");
  const [interfaceList, setInterfaceList] = useState<OperationObject[]>([]);



  useEffect(() => {
    queryUpstreamServiceList().then(data => {
      if(Array.isArray(data) && data.length > 0) {
        setUpstreamServiceList(data);
        handleUpstreamServiceClick(data[0].upstreamServiceIdentifier)
      }
    })
  }, [])

  const handleUpstreamServiceClick = useCallback((upstreamServiceIdentifier: string) => {
    setActiveIdentifier(upstreamServiceIdentifier);
    queryUpstreamServiceInfo({upstreamServiceIdentifier}).then(data => {
      const { host= "", basePath="" } = data as any;
      setInterfaceBasePath(host + basePath);
      ProcessSpec(data)
        .then((spec: ProcessSpecResult) => {
          setInterfaceList(spec.paths);
        })
        .catch((err: Error) => {
          console.error(err)
        });
    })
  }, [])

  const columns = [
    {
      title: "接口路径",
      dataIndex: "path",
      render: (text: any, record: any) => {
        return (
          <>
            <span className={cx("summary-method")}>{record.method}</span>
            <span>{record.path}</span>
          </>
        );
      },
    },
    { title: "接口描述", dataIndex: "summary", key: "summary" },
    { title: "tag", dataIndex: "tags", key: "tags" },
  ];

  const GuideList = (<DocumentAnchor documentTree={documentTree} />);

  const InterfaceList = (
    <>
      <ul className={styles.list}>
        {upstreamServiceList.map((item) => {
          return (
            <li
              key={item.upstreamServiceIdentifier}
              className={cx(activeIdentifier === item.upstreamServiceIdentifier ?"isActive": "")}
              onClick={() => handleUpstreamServiceClick(item.upstreamServiceIdentifier)}
            >
              <span>{item.upstreamServiceName}</span>
            </li>
          );
        })}
      </ul>
    </>
  );


  const expandedRowRender = (record: OperationObject) => {
  const { parameters = [], requestBody = { content: {} }, responses = {} } = record;
    let requestParams: RequestParam[] = [];
    parameters.forEach((parameter) => {
      const { name, in: _in, required, schema = {}, description="" } = parameter;
      requestParams.push({
        name,
        in: _in,
        required,
        description,
        type: schema.type,
        format: schema.format,
      });
    });
    if (requestBody.content) {
      Object.values(requestBody.content).forEach(content => {
        const { schema, examples, example } = content;
        const { required, description } = requestBody;
        if (schema) {
          const schemaTree = schemaToModel(schema, {});
          const _example = generateExample(
            examples,
            example,
            schema,
            "text"
          );
          requestParams.push({
            type: schema.type,
            format: schema.format,
            name: "body",
            required,
            in: "body",
            description,
            schemaTree,
            example: _example[0] && _example[0].exampleValue ? _example[0].exampleValue : '',
          });
        }
      })

    }
    let responseParams: ResponseParam[] = Object.keys(responses).map((name) => {
      const response = responses[name];
      const { description = "", headers = {} } = response;
      let _headers: ResponseParamHeader[] = [];
      if (headers) {
        _headers = Object.keys(headers).map((key) => {
          return {
            name: key,
            ...headers[key],
          };
        });
      }
      if (response.content) {
        for(let content of Object.values(response.content)) {
          const { schema, examples, example } = content;
          if (schema) {
            const schemaTree = schemaToModel(schema, {});
            const _example = generateExample(
              examples,
              example,
              schema,
              "text"
            );
            return {
              name,
              description,
              schemaTree,
              example: _example[0] && _example[0].exampleValue ? _example[0].exampleValue : '',
              headers: _headers,
            };
          }
        }
      }
      return {
        name,
        description,
        headers: _headers,
      };
    });
    return (
      <>
        <div className={cx("block-section")}>
          <div className={cx("block-description-wrapper")}>
            <div className={cx("block-description")}>
              <div className={cx("markdown")}>
                <p>{record.description}</p>
              </div>
            </div>
          </div>
          <p className={cx("block-section-header")}><h4>Parameters</h4></p>
          <RequestTable data={requestParams} />
          <p className={cx("block-section-header")}><h4>Responses</h4></p>
          <ResponseTable data={responseParams} />
        </div>
      </>
    );
  };


  const InterfaceTable = (
    <>
      <div className={cx("base-path-wrapper")} style={{display: !!interfaceBasePath ? '': 'none'}}>api根路径：{interfaceBasePath}</div>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
          rowExpandable: () => true,
        }}
        dataSource={interfaceList}
        pagination={false}
        expandRowByClick
        rowKey="uuid"
      />
    </>
  );

  function createMarkup() {
    return {__html: markdownHtml};
  }

  return (
    <>
      <div className={styles.container}>
        <aside className={styles.aside}>
          <Tabs
            activeKey={activeKey}
            onChange={(activeKey) => setActiveKey(activeKey)}
          >
            <TabPane tab="开发指南" key="1">
              {GuideList}
            </TabPane>
            <TabPane tab="接口列表" key="2">
              {InterfaceList}
            </TabPane>
          </Tabs>
        </aside>

        <main className={cx("main", Number(activeKey) > 1 ? "" : "markdown-body")} id="main-scroll-wrapper">
          {Number(activeKey) > 1 ? InterfaceTable : <div dangerouslySetInnerHTML={createMarkup()}/>}
        </main>
      </div>
    </>
  );
}

export default InterfaceMain;
