import React, {useCallback, useEffect, useState} from "react";
import {Row, Col, Statistic, Button, Input, Tooltip, Popconfirm} from "antd";
import styles from './index.module.scss';
import Header from "../../../../open-platform-commons/components/TableModule/Header";
import {Table} from "../../../../open-platform-commons/components/TableModule";
import {Link} from "react-router-dom";
import {Paging, usePaging} from "@maxtropy/central-commons-ui/es/components";
import {
  ApplicationList,
  deleteApplication,
  getApplicationList,
  GetApplicationRequest
} from "../../../api/application";
import {getStatsBrief, StatsBrief, SystemStatusType} from "../../../api/stats";
import TooltipText from "../../../../open-platform-commons/components/TooltipText";

const ConsoleList: React.FC = () => {

  const pagingInfo = usePaging();
  const {pageOffset, pageSize, setTotalCount, setPageOffset} = pagingInfo;
  const [data, setData] = useState<ApplicationList[]>([]);
  const [status, setStatus] = useState<StatsBrief>();
  const [searchParams, setSearchParams] = useState<GetApplicationRequest>({});

  const getInfo = useCallback(() => {
    getStatsBrief().then(data => {
      setStatus(data)
    })
  }, []);

  useEffect(() => {
    (async () => {
      await getInfo()
    })().catch(e => console.error(e))
  }, [getInfo]);

  useEffect(() => {
    getApplicationList({
      ...searchParams,
      count: pageSize,
      offset: pageOffset
    }).then(data => {
      setTotalCount(data.total);
      setData(data.contents);
    })
  }, [searchParams, pageSize, pageOffset]);

  const columns = [{
    title: '编号',
    width: 80,
    fixed: true,
    ellipsis: {showTitle: true},
    dataIndex: 'id',
    render: (record: number) => <TooltipText text={record} />
  }, {
    title: '应用名称',
    dataIndex: 'name',
    render: (record: string) => <TooltipText text={record} />
  }, {
    title: '所属机构',
    dataIndex: 'institutionName',
    render: (record: string) => <TooltipText text={record} />
  }, {
    title: '应用ID',
    dataIndex: 'appKey',
    render: (record: string) => <TooltipText text={record} />
  }, {
    title: '备注',
    ellipsis: {showTitle: true},
    dataIndex: 'memo',
    render: (record: string) => <TooltipText text={record} />
  }, {
    title: '操作',
    fixed: 'right' as 'right',
    width: 110,
    render: (record: ApplicationList) => (
      <div className={styles.action}>
        <Link to={`/developer/show/${record.id}`}>查看</Link>
        <Popconfirm title='是否要删除该应用？' placement="top"
                    okText='确认' cancelText='取消' onConfirm={() => deleteById(record)}>
          <a>删除</a>
        </Popconfirm>
      </div>
    )
  }];

  const deleteById = (record: ApplicationList) => {
    deleteApplication(record.id).then(data => {
      setSearchParams({...searchParams});
      getInfo()
    })
  };

  return (
    <div className={styles.content}>
      <Row className={styles.top}>
        <Col span={6}>
          <Statistic title="我的应用数量" value={`${(status && status.applicationCount) ?? '0'}个`} />
        </Col>
        <Col span={6}>
          <Statistic title="我的接口数量" value={`${(status && status.endpointCount) ?? '0'}个`} />
        </Col>
        <Col span={6}>
          <Statistic title="累计接口调用次数" value={`${(status && status.invocationCount) ?? '0'}次`} />
        </Col>
        <Col span={6}>
          <Tooltip title={status && status.systemStatusDescription}>
            <Statistic title="系统运行状态"
                       value={status && status.systemStatus === SystemStatusType.ABNORMAL ? "异常" : "正常"}
                       valueStyle={{ color: `${status && status.systemStatus === SystemStatusType.ABNORMAL ? 'red' : '#1DC499'}`}}
                       prefix={
                         <span className={styles.circle}
                                     style={{background: `${status && status.systemStatus === SystemStatusType.ABNORMAL ? 'red' : '#1DC499'}`}} />} />
          </Tooltip>
        </Col>
      </Row>
      <div className={styles.container}>
        <Header title='我的应用'>
          <div className={styles.search}>
            <Input.Search placeholder='请输入应用名称'
                          className={styles.searchInput}
                          onSearch={value => {
                            setSearchParams({...searchParams, byName: value});
                            setPageOffset(0)
                          }}/>
            <Button type='primary' href={'/developer/create'}>+添加应用</Button>
          </div>
        </Header>
        <Table columns={columns} dataSource={data} />
        <Paging pagingInfo={pagingInfo}  className={styles.pagination} />
      </div>
    </div>
  )
};

export default ConsoleList
