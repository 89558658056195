import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Divider, Button, Space, Row, Col, Spin } from 'antd';

import Breadcrumb from '../../../open-platform-commons/components/Breadcrumb';
import Title from '../../../open-platform-commons/components/Title';
import Desc from '../../../open-platform-commons/components/Desc';


import DynamicForm from '../../../open-platform-commons/components/form-module/DynamicForm';
import { DynamicFormProps, DynamicFormType } from '../../../open-platform-commons/components/form-module/DynamicForm/entity';
import Show from '../../../open-platform-commons/components/form-module/DynamicForm/module/Show';

import { queryUpstreamServiceList } from '../../../open-platform-commons/api/upstreamService';
import { getAppAuthorityDynamicForm, getAppInfo, AppDataProps } from '../../api/application';
import {AuthorityJson} from '../../../open-platform-commons/api/entity';

import Key from '../../../open-platform-commons/components/form-module/Key';

import styles from './index.module.scss';

const { useForm } = Form;



const baseUpstreamForm: DynamicFormProps = {
  fieldName: "upstreamServiceIdentifier",
  label: "所属应用：",
  type: DynamicFormType.SELECT,
  data: [],
  effectUpdate: true,
  // rules: [{ required: true, message: '所属应用不能为空' }],
  selfProps: {
    hasSearch: false,
  }
}

const breadcrumbRoutes = [
  {breadcrumbName: '控制台'},
  {breadcrumbName: '应用详情'},
];

const ShowApplication: React.FC = () => {
  const { appId } = useParams<{appId: string}>();
  const history = useHistory();

  const [form] = useForm();

  const [localEffectUpdate, ] = useState<string[]>(['upstreamServiceIdentifier']);

  const [upstreamForm, setUpstreamForm] = useState(baseUpstreamForm);

  const [loading, setLoading] = useState<boolean>(false);

  const [dynamicForm, setDynamicForm] = useState<DynamicFormProps[]>([]);

  const dynamicEffectUpdate = useMemo(() => {
    return dynamicForm.filter(j => j.effectUpdate).map(j => j.fieldName)

  }, [dynamicForm]);

  const effectUpdate = useMemo(() => {
    return [...localEffectUpdate, ...dynamicEffectUpdate]
  }, [localEffectUpdate, dynamicEffectUpdate]);

  const getUpstream = useCallback(() => {
    return queryUpstreamServiceList().then(res => {
      const data = res.map(i => ({
        label: i.upstreamServiceName,
        value: i.upstreamServiceIdentifier
      }));
      setUpstreamForm({
        ...upstreamForm,
        data,
      });
      return;
    })
  }, [upstreamForm])

  useEffect(() => {
    setLoading(true);
    getUpstream().then(() => {
      getAppData();
    })
  }, [appId]);

  const getAppData = () => {
    getAppInfo(appId!).then(res => {
      refreshForm(res, localEffectUpdate, dynamicEffectUpdate).then(() => {
        const allKeys = Object.keys(res) as (keyof AppDataProps)[];
        const authorityJson: AuthorityJson = JSON.parse(res.authorityJson)
        const formData: {[index: string]: any} = {}
        allKeys.filter(i => i !== "authorityJson").forEach((i)=> {
          formData[i] = res[i]
        })
        authorityJson.authority.forEach(i => {
          formData[i.field] = i.values;
        })
        form.setFieldsValue(formData);
        setLoading(false);
      })
    })
  }

  const getDynamicForm = (data: any) => {
    return getAppAuthorityDynamicForm(data).then(res => {
      setDynamicForm(res.configItems);
      return res;
    })
  }

  const refreshForm = useCallback((formData: any, localEffectUpdateKeys: string[], dynamicEffectUpdateKeys: string[]) => {
    const data: {[index: string]: any} = {};

    const isLocalupdate = localEffectUpdateKeys.every(i => {
      const item = formData[i];
      if (Array.isArray(item)) {
        return item.length > 0;
      }
      return !!item;
    })

    if (!isLocalupdate) {
      setDynamicForm([]);
      form.resetFields();
      return Promise.resolve([] as DynamicFormProps[]);
    } else {
      localEffectUpdateKeys.forEach(i => {
        data[i] = formData[i];
      })
      // const params: {[index: string]: any} = {};
      // dynamicEffectUpdateKeys.forEach(i => {
      //   params[i] = formData[i];
      // })
      // data['params'] = params
      setLoading(true);
      return getDynamicForm(data).then(res => res.configItems);
    }
  }, [form])

  const onValuesChange = (value: any) => {
    const keys = Object.keys(value);
    let update = false;
    keys.forEach(item => {
      if (effectUpdate.includes(item)) {
        update = true;
      }
    })
    if (update) {
      const formData = form.getFieldsValue();
      refreshForm(formData, localEffectUpdate, dynamicEffectUpdate).finally(() => {setLoading(false)})
    }
  }

  return (
    <>
      <Breadcrumb routes={breadcrumbRoutes} />
      <Title title="应用详情" />
      <Spin spinning={loading}>
        <div className={styles.wrapper}>
          <Form
            form={form}
            className={styles.formWrapper}
            layout="vertical"
            onValuesChange={onValuesChange}
          >
            <div className={styles.formItem}>
              <div className={styles.groupName}>基本信息</div>
            </div>
            <div className={styles.formItem}>
              <Row gutter={40}>
                <Col span={12}>
                  <Form.Item
                    label="应用名称："
                    name="name"
                  >
                    <Show />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="所属机构："
                    name="institutionName"
                    rules={[{ max: 40, message: '所属机构不得超过40个字符' }]}
                  >
                    <Show />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className={styles.formItem}>
              <Form.Item
                label="备注："
                name="memo"
                rules={[{ max: 50, message: '备注不得超过50个字符' }]}
              >
                <Show />
              </Form.Item>
            </div>
            <div className={styles.formItem}>
              <div className={styles.groupName}>
                允许能力范围
                <Desc>如需申请更多能力范围请联系管理员</Desc>
              </div>
            </div>
            <div className={styles.formItem}>
              <Row gutter={40}>
                <Col span={12}>
                  <DynamicForm {...upstreamForm} hasShow/>
                </Col>
              </Row>
            </div>
            {
              dynamicForm.map(i => (
                <div className={styles.formItem} key={i.fieldName}>
                  <DynamicForm {...i} hasShow/>
                </div>
              ))
            }
            <div className={styles.formItem}>
              <div className={styles.groupName}>开发信息</div>
            </div>
            <div className={styles.formItem}>
              <Form.Item
                name="appKey"
              >
                <Key name="应用ID" id={appId} />
              </Form.Item>
            </div>
            <Divider />
            <div className={styles.formItem}>
              <Space>
                <Button type="primary" onClick={() => {history.push(`/developer/create/${appId}`)}}>编辑</Button>
              </Space>
            </div>
          </Form>
        </div>
      </Spin>
    </>
  )
}

export default ShowApplication;
