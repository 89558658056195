import React, {useEffect, useState} from "react";
import { useParams } from 'react-router';
import Breadcrumb from "../../../../open-platform-commons/components/Breadcrumb";
import styles from './index.module.scss';
import {Descriptions, Divider, Button} from "antd";
import {DeveloperListResponse, getDeveloperByMcid} from "../../../api/developer";
import Dynamic from "../Dynamic";
import Title from "../../../../open-platform-commons/components/Title";
import {adCodeToName} from "../../../../open-platform-commons/utils/adcode";

const breadcrumbRoutes = [
  {breadcrumbName: '首页'},
  {breadcrumbName: '开发者管理', path: `/operator/manage`},
  {breadcrumbName: '开发者详情'},
];

const ManageDetail: React.FC = () => {

  const {mcid} = useParams();
  const [info, setInfo] = useState<DeveloperListResponse>();

  useEffect(() => {
    getDeveloperByMcid(mcid).then(data => {
      setInfo(data.developer);
    });
  }, []);

  return (
    <>
      <Breadcrumb routes={breadcrumbRoutes} />
      <Title title="开发者详情" />
      <div className={styles.background}>
        <div className={styles.container}>
          <Divider orientation="left">基本信息</Divider>
          <Descriptions column={2} layout='vertical' className={styles.des}>
            <Descriptions.Item label="客户名称">{info?.name || '--'}</Descriptions.Item>
            <Descriptions.Item label="客户完整名称">{info?.fullName || '--'}</Descriptions.Item>
            <Descriptions.Item label="负责人">{info?.contactName || '--'}</Descriptions.Item>
            <Descriptions.Item label="负责人手机">{info?.contactPhone || '--'}</Descriptions.Item>
            <Descriptions.Item label="账号">{info?.username || '--'}</Descriptions.Item>
            <Descriptions.Item label="客户地址">
              {info && info.adCode ? `${adCodeToName(info.adCode)}` : '--'}
            </Descriptions.Item>
          </Descriptions>
          <Divider orientation="left" style={{margin: '50px 0'}}>开发者权限范围</Divider>
          <Dynamic mcid={mcid} className='' hasShow />
          <Divider />
          <Button type='primary' href={`/operator/manage/edit/${mcid}`}>编辑权限</Button>
        </div>
      </div>
    </>
  )
};

export default ManageDetail
