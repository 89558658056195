import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory } from 'react-router';
import { Form, Divider, Button, Space, Spin } from 'antd';

import DynamicForm from '../../../../open-platform-commons/components/form-module/DynamicForm';
import { DynamicFormProps, DynamicFormType } from '../../../../open-platform-commons/components/form-module/DynamicForm/entity';

import { queryUpstreamServiceList } from '../../../../open-platform-commons/api/upstreamService';
import { getAuthorityDynamicForm, getDeveloper, saveOrUpdateDeveloper, DeveloperRequest } from '../../../api/developer';
import { AuthorityDynamicFormResponse, AuthorityJson } from '../../../../open-platform-commons/api/entity';

const { useForm } = Form;

const baseUpstreamForm: DynamicFormProps = {
  fieldName: "upstreamServiceIdentifier",
  label: "物联网应用范围：(多选)",
  type: DynamicFormType.SELECT,
  data: [],
  effectUpdate: true,
  rules: [{ type: 'array', required: true, message: '物联网应用范围不能为空' }],
  selfProps: {
    mode: 'multiple',
    hasSearch: false,
    placeholder: '请选择物联网应用范围',
  }
}

const getDynamicEffectUpdateKeys = (data: AuthorityDynamicFormResponse[]) => {
  return data.map(i => {
    return i.configItems.filter(j => j.effectUpdate).map(j => j.fieldName);
  }).flat();
}

interface DynamicProps {
  className: string;
  mcid: string;
  hasShow?: boolean;
}

const Dynamic: React.FC<DynamicProps> = (props) => {
  const {mcid, hasShow = false} = props;
  const history = useHistory();

  const [form] = useForm();

  const [upstreamForm, setUpstreamForm] = useState(baseUpstreamForm);
  const [loading, setLoading] = useState<boolean>(false);

  const [localEffectUpdate, ] = useState<string[]>(['upstreamServiceIdentifier']);

  const [dynamicForm, setDynamicForm] = useState<AuthorityDynamicFormResponse[]>([]);

  const dynamicEffectUpdate = useMemo(() => {
    return getDynamicEffectUpdateKeys(dynamicForm)
  }, [dynamicForm]);

  const effectUpdate = useMemo(() => {
    return [...localEffectUpdate, ...dynamicEffectUpdate]
  }, [localEffectUpdate, dynamicEffectUpdate]);

  const getUpstream = useCallback(() => {
    return queryUpstreamServiceList().then(res => {
      const data = res.map(i => ({
        label: i.upstreamServiceName,
        value: i.upstreamServiceIdentifier
      }));
      setUpstreamForm({
        ...upstreamForm,
        data,
      });
      return;
    })
  }, [upstreamForm])

  useEffect(() => {
    setLoading(true);
    getUpstream().then(() => {
      getDeveloperData();
    })
  }, []);

  const refreshForm = useCallback((formData: any, localEffectUpdateKeys: string[], dynamicEffectUpdateKeys: string[]) => {
    const data: {[index: string]: any} = {};

    const isLocalupdate = localEffectUpdateKeys.every(i => {
      const item = formData[i];
      if (Array.isArray(item)) {
        return item.length > 0;
      }
      return !!item;
    })

    if (!isLocalupdate) {
      setDynamicForm([]);
      form.resetFields();
      return Promise.resolve([] as AuthorityDynamicFormResponse[]);
    } else {
      localEffectUpdateKeys.forEach(i => {
        data[i] = formData[i];
      })
      const params: {[index: string]: any} = {};
      dynamicEffectUpdateKeys.forEach(i => {
        params[i] = formData[i];
      })
      data['params'] = params
      setLoading(true);
      return getDynamicForm(data);
    }
  }, [form])

  const getDeveloperData = useCallback(() => {
    getDeveloper(mcid).then(res => {
      const upstreamServiceIdentifier = res.developerAuthorities.map(i => i.upstreamServiceIdentifier);
      const formData: {[key: string]: any} = {
        upstreamServiceIdentifier,
      };
      res.developerAuthorities.forEach(i => {
        const data: AuthorityJson = JSON.parse(i.authorityJson);
        data.authority.forEach(item => {
          formData[item.field] = item.values
        })
      })
      getDynamicForm({upstreamServiceIdentifier, params: {}}).then((res) => {

        refreshForm(formData, localEffectUpdate, getDynamicEffectUpdateKeys(res)).then(() => {
          form.setFieldsValue(formData);
          setLoading(false);
        })
      })
    })
  }, [mcid, form, refreshForm, localEffectUpdate])

  const getDynamicForm = (data: any) => {
    // setLoading(true);
    return getAuthorityDynamicForm(data).then(res => {
      setDynamicForm(res);
      return res;
    })
  }

  const onValuesChange = (value: any) => {
    const keys = Object.keys(value);
    let update = false;
    keys.forEach(item => {
      if (effectUpdate.includes(item)) {
        update = true;
      }
    })
    if (update) {
      const formData = form.getFieldsValue();
      refreshForm(formData, localEffectUpdate, dynamicEffectUpdate).finally(() => {setLoading(false)})
    }
  }

  const onFinish = (data: any) => {
    const res: DeveloperRequest[] = dynamicForm.map(form => {
      const keys = form.configItems.map(i => i.fieldName);
      const obj = {
        authority: keys.map(key => ({
          field: key,
          values: data[key] ?? [],
        }))
      }
      return {
        upstreamServiceIdentifier: form.upstreamServiceIdentifier,
        authorityJson: JSON.stringify(obj)
      };
    })
    // TODO: 成功进行跳转
    saveOrUpdateDeveloper(mcid, res).then(res => {
      history.push('/operator/manage')
    })
  }

  // const FormItemClassName = classNames(styles.formItem, styles.edit)

  return (
    <Spin spinning={loading}>
      <Form form={form}
        // className={styles.formWrapper}
        layout="vertical"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        <DynamicForm {...upstreamForm} className={props.className} hasShow={hasShow}/>
        {
          dynamicForm.map(i => {
            return i.configItems.map(j => <DynamicForm key={j.fieldName} {...j} className={props.className} hasShow={hasShow}/>)
          })
        }
        {
          !hasShow && (
            <>
              <Divider />
              <Form.Item className={props.className}>
                <Space size={20}>
                  <Button type="primary" htmlType="submit">
                    提交
                  </Button>
                  <Button onClick={() => {history.push('/operator/manage')}}>
                    取消
                  </Button>
                </Space>
              </Form.Item>
            </>
          )
        }
      </Form>
    </Spin>
  )
}

export default Dynamic;
