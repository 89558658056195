import areaJson from '../assets/area.json';

interface AreaJsonProps {
  code: string;
  name: string;
  children?: AreaJsonProps[]
}

export function adCodeToName(target: string): string {
  let result: string = '';

  function f(areaJson: AreaJsonProps[], adCode: string) {
    areaJson.forEach(({code, name, children}) => {
      if (code === adCode || code === adCode.substring(0, 2) || code === adCode.substring(0, 4)) {
        result = result + name;
      }
      children && f(children, adCode)
    });
    return result;
  }

  return f(areaJson, target)
}