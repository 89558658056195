import qs from "qs";
import {fetch, Paged, PageRequest} from "@maxtropy/central-commons-ui";

import { AuthorityDynamicFormResponse } from '../../open-platform-commons/api/entity';

export interface DeveloperListRequest extends PageRequest {
  name?: string;
  auth_completed?: boolean;
  upstream_identifier?: string;
}

export interface DeveloperListResponse {
  authCompleted: boolean;
  contactName: string;
  contactPhone: string;
  fullName: string;
  mcid: string;
  name: string;
  address: string;
  username: string;
  id?: number;
  adCode?: string
}

export function getDeveloperList(query: DeveloperListRequest): Promise<Paged<DeveloperListResponse>> {
  return fetch(`/api/developer/search?${qs.stringify(query, {indices: false})}`)
}


export function getAuthorityDynamicForm(params: any, id?: string): Promise<AuthorityDynamicFormResponse[]> {
  const url = id ? `/api/developer/authority?developerId=${id}` : `/api/developer/authority`
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
  })
}

interface DeveloperResponse {
  developer: any;
  developerAuthorities: DeveloperRequest[];
}

export function getDeveloper(mcid: string): Promise<DeveloperResponse> {
  return fetch(`/api/developer/${mcid}`);
}

export interface DeveloperRequest {
  upstreamServiceIdentifier: string;
  authorityJson: string;
}

export function saveOrUpdateDeveloper(mcid: string, params: DeveloperRequest[]) {
  return fetch(`/api/developer/${mcid}`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
export interface Customer {
  id: number;
  fullName: string;
}

export function queryCustomerList(): Promise<Customer[]> {
  return fetch(`/api/developer/getCustomerList`)
}

interface GetDeveloperByMcidResponse {
  developer: DeveloperListResponse;
  developerAuthorities: []
}

export function getDeveloperByMcid(mcid: string): Promise<GetDeveloperByMcidResponse> {
  return fetch(`/api/developer/${mcid}`)
}

export interface DevelopAuthorityRequest {
  upstream: string;
  params: {
    mcid: string[]
  }
}

export function developAuthority(query: DevelopAuthorityRequest, developerId?: number) {
  return fetch(`/api/developer/authority?${developerId}`, {
    method: 'POST',
    body: JSON.stringify({...query})
  })
}
