import React, { forwardRef, useState, useImperativeHandle, useCallback } from 'react';
import { Input, Select as AntSelect } from 'antd';
import { SelectProps as AntSelectProps, SelectValue } from 'antd/lib/select';

import styles from './index.module.scss';

const { Search } = Input;

interface MultipleSelectProps extends Omit<AntSelectProps<SelectValue>, 'dropdownRender' | 'filterOption' | 'searchValue'> {
  hasSearch?: boolean;
  serachPlaceholder?: string;
}

export interface MultipleSelectRef {
  getonBlurDisable: () => boolean;
}


const MultipleSelect: React.ForwardRefRenderFunction<MultipleSelectRef, MultipleSelectProps> = (props, ref) => {
  const {hasSearch = true, children, serachPlaceholder, ...restProps} = props;
  const [onBlur_disable, setOnBlurDisable] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');

  useImperativeHandle(ref, () => ({
    getonBlurDisable: () => onBlur_disable,
  }));

  const dropdownRender = useCallback((menu: React.ReactElement) => {
    if (hasSearch) {
      return (
        <div
          onMouseEnter={() => setOnBlurDisable(true)}
          onMouseLeave={() => setOnBlurDisable(false)}
        >
          <Search
            placeholder={serachPlaceholder}
            className={styles.search}
            onKeyDown={(e) => {e.stopPropagation()}} 
            onChange={(e) => {
              if (!e.target.value) {
                setSearch('');
              }
            }}
            onSearch={(value) => {setSearch(value)}}
          />
          {menu}
        </div>
      )
    } else {
      return menu
    }
  }, [hasSearch]);
  
  return (
    <AntSelect
      searchValue={search}
      filterOption={(value, options) => {return (options?.label! as string).includes(value)}}
      dropdownRender={dropdownRender}
      className={styles.select}
      {...restProps}
    >
      {children}
    </AntSelect>
  )
}

export default forwardRef(MultipleSelect);