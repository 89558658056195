import { ReactElement } from 'react';

export enum DynamicFormType {
  SELECT = 'select',
  TREESELECT = 'tree-select',
  TREE = 'tree',
  TABCHECKBOX = 'tab-checkbox',
}
export interface DynamicFormItemProps {
  type: DynamicFormType;
  data: any;
  effectUpdate?: boolean;
  selfProps: any;
  hasShow?: boolean;
}

interface Rule {
  len?: number;
  max?: number;
  message?: string | ReactElement;
  min?: number;
  pattern?: RegExp;
  required?: boolean;
  type?: any;
  whitespace?: boolean;
  /** Customize rule level `validateTrigger`. Must be subset of Field `validateTrigger` */
  validateTrigger?: string | string[];
}

export interface DynamicFormProps extends DynamicFormItemProps {
  fieldName: string;
  label: string;
  rules?: Rule[];
}
