import React from "react";
import classNames from "classnames/bind";
import styles from "./index.module.scss";


interface BracketLeftProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  data: object | any[];
  isLastKey: boolean;
}

const cx = classNames.bind(styles);

const BracketLeft: React.FC<React.PropsWithChildren<BracketLeftProps>> = ({ visible, setVisible, data, isLastKey, children}) => {

  const toggleBrackets = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setVisible(!visible);
    
  }

  const bracketsFormatter = (brackets: string) => {
    return isLastKey ? `${brackets},` : brackets
  }

  const doubleBracketsGenerator = (data: object | any[]) => {
    let brackets = Array.isArray(data) ? "[...]" : "{...}";
    return bracketsFormatter(brackets);
  };

  return (
    <>
      {children}
      <span
        style={{ display: visible ? "" : "none" }}
        className={cx("sw-tree-bracket-left")}
        onClick={toggleBrackets}
      >
        {Array.isArray(data) ? "[" : "{"}
      </span>
      <span
        style={{ display: !visible ? "" : "none" }}
        className={cx("sw-tree-bracket-left")}
        onClick={toggleBrackets}
      >
        {doubleBracketsGenerator(data)}
      </span>
    </>
  );
};

export default BracketLeft;
