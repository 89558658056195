import React, { useEffect, useState, useRef } from 'react';
import { SelectProps as AntSelectProps, SelectValue } from 'antd/lib/select';

import MultipleSelect, { MultipleSelectRef } from './MultipleSelect';

interface MutipleSelectLazyFocusProps extends Omit<AntSelectProps<SelectValue>, 'onChange' | 'tagRender'> {
  onChange?: (value: any) => void;
  children?: React.ReactNode;
  hasSearch?: boolean;
  serachPlaceholder?: string;
}

const MutipleSelectLazyFocus: React.FC<MutipleSelectLazyFocusProps> = (props) => {
  const { value, onChange, onBlur, ...restProps } = props;
  const [localValue, setLocalValue] = useState<any>();
  const ref = useRef<MultipleSelectRef>(null);
  // 控制select的展开状态
  const [open, setOpen] = useState<boolean>(false);
  // 展开后是否做操作
  const [update, setUpdate] = useState<boolean>(false);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const fireChange = (value: any) => {
    onChange && onChange(value);
  }

  const localChange = (values: any) => {
    if (!open) {
      fireChange(values);
    } else {
      setUpdate(true);
      setLocalValue(values);
    }
  }

  const localBlur = (e?: React.FocusEvent<HTMLElement>) => {
    if (ref.current && !ref.current?.getonBlurDisable()) {
      setOpen(false)
    }
    if (update) {
      fireChange(localValue);
    }
    onBlur && onBlur(e!)
  }

  

  return (
    <MultipleSelect
      ref={ref}
      onChange={localChange}
      onBlur={localBlur}
      onFocus={() => {setOpen(true); setUpdate(false)}}
      value={localValue}
      open={open}
      {...restProps}
    >
      {props.children}
    </MultipleSelect>
  )
}

export default MutipleSelectLazyFocus;