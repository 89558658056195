import React from 'react';
import classNames from "classnames/bind";
import styles from "./index.module.scss";

interface BracketRightProps {
  data: object | any[];
  visible: boolean;
  isLastKey: boolean;
  setVisible: (visible: boolean) => void;
}

const cx = classNames.bind(styles);

const BracketRight: React.FC<BracketRightProps> = ({ visible, data, isLastKey, setVisible }) => {

  const toggleBrackets = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setVisible(!visible);
  }

  const bracketsFormatter = () => {
    const brackets = Array.isArray(data) ? "]" : "}";
    return isLastKey ? `${brackets},` : brackets;
  };

  return (
    <div style={{ display: visible ? '' : "none" }}>
      <span className={cx("sw-tree-bracket-right")} onClick={toggleBrackets}>
        {bracketsFormatter()}
      </span>
    </div>
  );
}

export default BracketRight;
