import React, { ReactNode } from 'react';
import styles from './index.module.scss';

interface TitleProps {
  title: string | ReactNode;
}

const Title: React.FC<TitleProps> = (props) => {
  return (
    <div className={styles.title}>{props.title}</div>
  )
}

export default Title;
