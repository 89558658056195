import React from 'react';
import JsonTree, { JsonTreeProps } from './index';

export const JsonTreeContext = React.createContext({ showDescr: false, format: "text" });
JsonTreeContext.displayName = "JsonTreeContext";

interface JsonTreeContentProps extends JsonTreeProps {
  format: string;
  showDescr?: boolean;
}

const JsonTreeContent: React.FC<JsonTreeContentProps> = ({
  showDescr = false,
  format = "text",
  ...restProps
}) => {
  return (
    <JsonTreeContext.Provider value={{ showDescr, format }}>
      <JsonTree {...restProps}></JsonTree>
    </JsonTreeContext.Provider>
  );
};

export default JsonTreeContent;
