import React from "react";
import {Tooltip} from "antd";

interface TooltipTextProps {
  text?: string | number
}

const TooltipText: React.FC<TooltipTextProps> = (props) => {
  return (
    <>
      <Tooltip title={props.text}>
        <span>{props.text || '--'}</span>
      </Tooltip>
    </>
  )
};

export default TooltipText
