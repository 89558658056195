import {Anchor} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {TreeNode} from "../../markdown-utils";
import AnchorLink from "antd/lib/anchor/AnchorLink";

const {Link} = Anchor;

interface DocumentAnchorProps {
  documentTree: TreeNode[];
}

const DocumentAnchor: React.FC<DocumentAnchorProps> = ({documentTree}) => {

  const renderLinks = (treeNodes: TreeNode[]): AnchorLink[] | undefined => {
    return treeNodes.length > 0 ? treeNodes.map(
      ({href, title, children}) => (
        <Link href={href} title={title} key={href} >
          {Array.isArray(children) && children.length > 0 ? renderLinks(children) : undefined}
        </Link>
      ) as unknown as AnchorLink
      ) :
      undefined;
  }

  const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
  }, [])


  return (
    <Anchor
      affix={false}
      showInkInFixed={false}
      getContainer={() => document.getElementById("main-scroll-wrapper")!}
      onClick={handleClick}>
      {renderLinks(documentTree)}
    </Anchor>
  )
}

export default DocumentAnchor;
