import qs from "qs";
import { fetch } from "@maxtropy/central-commons-ui";

export enum SystemStatusType {
  // 正常
  NORMAL = "NORMAL",
  // 异常
  ABNORMAL = "ABNORMAL",
}

export interface StatsBrief {
  // 应用数量
  applicationCount: number;
  // 接口数量
  endpointCount: number;
  // 累计调用接口次数
  invocationCount: number;
  // 开发者数量
  developerCount: number;
  // 系统运行状态
  systemStatus: SystemStatusType;
  // 运行状态说明文字
  systemStatusDescription?: string;
}

export enum AggrByType {
  MONTH = "MONTH",
  DAY = "DAY",
  HOUR = "HOUR",
}

export interface Invocation {
  name: string;
  count: number;
}

export interface InvocationByTime {
  time: string;
  value: number;
}

// ISO string
export interface DateRangeQuery {
  start: string;
  end: string;
}

export interface InvocationsTopQuery extends DateRangeQuery {
  size?: number; // 默认10
}

export interface InvocationsTrendQuery extends DateRangeQuery {
  aggrBy: AggrByType;
}

export const getInvocationsTop = (query: InvocationsTopQuery) =>
  fetch<Invocation[]>(
    `/api/stats/invocations/endpoint/count/top?${qs.stringify(query)}`
  );

export const getInvocationsTrend = (query: InvocationsTrendQuery) =>
  fetch<InvocationByTime[]>(
    `/api/stats/invocations/count?${qs.stringify(query)}`
  );

export function getStatsBrief(): Promise<StatsBrief> {
  return fetch(`/api/stats/brief`);
}
