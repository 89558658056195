import {fetch} from "@maxtropy/central-commons-ui";

export interface User {
  id:number;
  username:string;
  name: string;
}

interface DeveloperUser extends User{
  mcid: string;
  fullName: string;
  address: string;
  contactName: string;
  contactPhone: string;
  createTime: string;
  updateTime: string;
  authCompleted: boolean;
}

//应用者侧
export function getDeveloper(): Promise<DeveloperUser> {
  return fetch(`/api/developer/self`);
}

interface OperatorUser extends User{
  authorities: string[];
  cellphone: string;
}


//运维者侧
export function getOperator(): Promise<OperatorUser> {
  return fetch(`/api/operator/self`);
}
