import React, { useEffect } from 'react';
import { Select as AntdSelect } from 'antd';

import MultipleSelect from './MultipleSelect';
import MutipleSelectLazyFocus from './MutipleSelectLazyFocus';
import Show from '../Show';

import { DynamicFormItemProps } from '../../entity';

interface SelectData {
  label: string;
  value?: React.Key;
  children?: SelectData[]
}
export interface SelectProps extends Omit<DynamicFormItemProps, 'self_props' | 'data'> {
  data: SelectData[];
  selfProps: {
    mode?: 'multiple' | 'tags';
    hasSearch?: boolean;
    placeholder?: string;
    serachPlaceholder?: string;
  };
  value?: React.Key | React.Key[];
  onChange?: (data: any) => void;
}

const buildValue = (data: SelectData[] = []) => {
  const getValue = (value: React.Key[] | React.Key) => {
    if (!Array.isArray(value)) {
      value = [value]
    }
    const res: string[] = [];
    data.forEach(i => {
      if (i.children && i.children.length) {
        const childGetValue = buildValue(i.children)
        res.push(...childGetValue(value))
      } else {
        if ((value as React.Key[]).includes(i.value!)) {
          res.push(i.label)
        }
      }
    })
    return res;
  }
  return getValue;
}

const buildChildren = (data: any[]) => {
  return (data ?? []).map(i => {
    if (i.children && i.children.length) {
      return (
        <AntdSelect.OptGroup label={i.label}>
          {buildChildren(i.children)}
        </AntdSelect.OptGroup>
      )
    } else {
      return (
        <AntdSelect.Option value={i.value} label={i.label}>{i.label}</AntdSelect.Option>
      )
    }
  })
}

const getChildKey = (data: SelectData[]) => {
  const res: React.Key[] = [];
  data.forEach(i => {
    if (i.children && i.children.length) {
      res.push(...getChildKey(i.children))
    } else {
      res.push(i.value!);
    }
  })
  return res;
}

const Select: React.FC<SelectProps> = (props) => {
  const { effectUpdate, selfProps: { mode, hasSearch, placeholder = '', serachPlaceholder = '' }, hasShow = false, data, type, ...restProps } = props;

  useEffect(() => {
    const { value, onChange } = props;
    if(!value) {
      return;
    }
    const childKeys = getChildKey(data ?? []);
    if (!Array.isArray(value)) {
      if (!childKeys.includes(value)) {
        onChange && onChange(null);
      }
    } else {
      const newValue = value.filter(i => childKeys.includes(i));
      if (value.length !== newValue.length) {
        onChange && onChange(newValue);
      }
    }
  }, [data])

  return (
    hasShow ? (
      <Show buildValue={buildValue(data)} {...restProps} />
    ) : (
      mode ? (
        effectUpdate ? (
          <MutipleSelectLazyFocus
            placeholder={placeholder}
            serachPlaceholder={serachPlaceholder}
            mode={mode}
            hasSearch={hasSearch}
            {...restProps}
          >
            {buildChildren(data)}
          </MutipleSelectLazyFocus>
        ) : (
          <MultipleSelect
            placeholder={placeholder}
            serachPlaceholder={serachPlaceholder}
            mode={mode}
            hasSearch={hasSearch}
            {...restProps}
          >
            {buildChildren(data)}
          </MultipleSelect>
        )
      ) : (
        <AntdSelect placeholder={placeholder} {...restProps}>
          {buildChildren(data)}
        </AntdSelect>
      )
    )
    )
}

export default Select;