import React from "react";
import { useParams } from 'react-router'
import classNames from 'classnames';

import Breadcrumb from '../../../../open-platform-commons/components/Breadcrumb';
import Title from '../../../../open-platform-commons/components/Title';

import Dynamic from '../Dynamic';

import styles from './index.module.scss';

const breadcrumbRoutes = [
  {breadcrumbName: '首页'},
  {breadcrumbName: '开发者管理'},
  {breadcrumbName: '开发者详情'},
];

const ManageEdit: React.FC = () => {
  const { mcid } = useParams<{mcid: string}>();

  const FormItemClassName = classNames(styles.formItem, styles.edit)

  return (
    <>
      <Breadcrumb routes={breadcrumbRoutes} />
      <Title title="开发者权限范围" />
      <div className={styles.wrapper}>
        <div className={styles.formWrapper}>
          <Dynamic mcid={mcid} className={FormItemClassName} />
        </div>
      </div>
    </>
  )
}

export default ManageEdit;
