import React from "react";
import { Header } from "../layout/Header";
import styles from "./index.module.scss";

export interface NotAuthorizedProps {
  text?: string;
}

const NotAuthorized: React.FC<NotAuthorizedProps> = props => {
  const {text = "您无权访问该应用，请订阅应用后登录"} = props;
  return (
    <>
      <Header />
      <div className={styles.pageNotAuthorized}>
        <div className={styles.content}>
          <div className={styles.logo}/>
          <div className={styles.text}>{text}</div>
        </div>
      </div>
    </>
  )
}

export default NotAuthorized;
