import React, { useEffect, useState} from 'react';
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import Breadcrumb from '../../../open-platform-commons/components/Breadcrumb';
import Header from '../../../open-platform-commons/components/TableModule/Header';
import { Input, Select } from 'antd';
import { Paging, usePaging } from '@maxtropy/central-commons-ui/es/components';
import { Link, useParams } from 'react-router-dom';
import { Table } from '../../../open-platform-commons/components/TableModule';
import {
  OperationApplication,
  queryApplicationAll,
  QueryApplicationAllRequest
} from "../../../developer/api/application";
import {Customer, queryCustomerList} from "../../api/developer";
import { omitBy } from 'lodash';

const cx = classNames.bind(styles);

const routes = [
  { breadcrumbName: "首页" },
  { breadcrumbName: "应用查询" },
];

const ellipsisRender = (text: any) => (text || '--');

const columns = [
  {
    title: "应用名称",
    ellipsis: { showTitle: true },
    dataIndex: "name",
    render: ellipsisRender
  },
  {
    title: "所属客户",
    ellipsis: { showTitle: true },
    dataIndex: "customerName",
    render: ellipsisRender
  },
  {
    title: "账号",
    ellipsis: { showTitle: true },
    dataIndex: "username",
    render: ellipsisRender
  },
  {
    title: "负责人",
    width: 150,
    ellipsis: { showTitle: true },
    dataIndex: "contactName",
    render: ellipsisRender
  },
  {
    title: "负责人手机",
    width: 150,
    ellipsis: { showTitle: true },
    dataIndex: "contactPhone",
    render: ellipsisRender
  },
  {
    title: "备注",
    ellipsis: { showTitle: true },
    dataIndex: "memo",
    render: ellipsisRender
  },
  {
    title: "操作",
    width: 140,
    render: (record: any) => (
      <div>
        <Link to={`/operator/application/${record.developerId}/show/${record.id}`}>查看</Link>
      </div>
    ),
  },
];

interface CustomerOption extends Customer {
  label: string;
  value: string;
}

const Application = () => {
  const { developerId } = useParams();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [data, setData] = useState<OperationApplication[]>([]);
  const [searchParams, setSearchParams] = useState<QueryApplicationAllRequest>({developerId});
  const [customers, setCustomers] = useState<CustomerOption[]>([{fullName: "全部", id: -1, label: "全部", value: "-1"}]);

  useEffect(() => {
    queryCustomerList().then(data => {
      if(Array.isArray(data) && data.length > 0) {
        const _data = [...customers, ...data,];
        setCustomers(_data.map(item => ({...item, label: item.fullName, value: item.id.toString()})));
      }
    })
  }, [])

  useEffect(() => {
    queryApplicationAll({
      ...omitBy(searchParams, (value, key) => {
        return (key === "developerId" && value === -1) || (key === "byName" && !value);
      }),
      count: pageSize,
      offset: pageOffset
    }).then(data => {
      setTotalCount(data.total);
      setData(data.contents);
    })
  }, [searchParams, pageSize, pageOffset]);


  const onChange = (value: string) => {
    setSearchParams(() => ({...searchParams, developerId: Number(value)}));
    setPageOffset(0);
  };

  const onSearch = (value: string) => {
    setSearchParams(() => ({...searchParams, byName: value}));
    setPageOffset(0);
  };

  const rightContent =(
    <>
      <label>所属客户：</label>
      <Select
        showSearch
        style={{ width: 200, marginRight: "20px" }}
        optionFilterProp="children"
        defaultValue={"-1"}
        onChange={onChange}
        options={customers}
        value={searchParams.developerId ? searchParams.developerId.toString() : "-1"}
      >
      </Select>
      <Input.Search placeholder="请输入应用名称" style={{ width: 200 }} onSearch={onSearch} />
    </>
  );

  return (
    <>
      <Breadcrumb routes={routes} />
      <div className={cx("container")}>
        <Header title="应用查询">
          <div className={cx("search")}>
            <div>{rightContent}</div>
          </div>
        </Header>
        <Table columns={columns} dataSource={data} />
        <Paging pagingInfo={pagingInfo} className={cx("pagination")} />
      </div>
    </>
  );
}

export default Application;
