import React from 'react';
import BaseTabCheckbox from './BaseTabCheckbox';
import { DynamicFormItemProps } from '../../entity';

interface TabCheckboxProps extends Omit<DynamicFormItemProps, 'self_props'> {}

const TabCheckbox: React.FC<TabCheckboxProps> = (props) => {
  const { data, type, effectUpdate, ...restProps } = props;
  return (
    <BaseTabCheckbox options={data} {...restProps} />
  )
}

export default TabCheckbox;
