import React from 'react';

import { DynamicFormItemProps } from '../../entity';

import TreeForm from './TreeForm';

export interface TreeProps extends Omit<DynamicFormItemProps, 'self_props'> {
  selfProps: {
    hasSearch?: boolean;
    searchPlaceholder?: string;
  };
}

const Tree: React.FC<TreeProps> = (props) => {

  const { data, selfProps: { hasSearch, searchPlaceholder = '' }, ...restProps } = props;

  return (
    <TreeForm 
      hasSearch={hasSearch}
      treeData={data}
      checkable
      selectable={false}
      searchPlaceholder={searchPlaceholder}
      {...restProps}
    />
  )
}

export default Tree;