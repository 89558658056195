import React from "react";
import {Table as AntDTable} from "antd";
import styles from "./index.module.scss";
import {TableProps } from "antd/lib/table";

function Table<T extends object>(props: TableProps<T>) {

  return (
    <AntDTable<T>
      {...props}
      tableLayout={'fixed'}
      bordered
      className={styles.table}
      pagination={false}
    />
  )
}

export default Table;
