import React from "react";
import { Breadcrumb as AntDBreadcrumb } from "antd";
import { BreadcrumbProps as AntBreadcrumbProps, Route as AntRoute } from "antd/lib/breadcrumb/Breadcrumb";
import styles from "./index.module.scss";

interface Route extends Pick<AntRoute, 'breadcrumbName'> {
  path?: string;
}

interface BreadcrumbProps extends Omit<AntBreadcrumbProps, 'routes' | 'className'> {
  routes: Route[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {

  const { routes, ...restProps } = props;

  return (
    <AntDBreadcrumb className={styles.breadcrumb} {...restProps}>
      {
        routes.map(route => {
          return route?.path ? (
            <AntDBreadcrumb.Item key={route.breadcrumbName}>
              <a href={route.path}>{route.breadcrumbName}</a>
            </AntDBreadcrumb.Item>
          ) : (
            <AntDBreadcrumb.Item key={route.breadcrumbName}>
              {route.breadcrumbName}
            </AntDBreadcrumb.Item>
          )
        })
      }
    </AntDBreadcrumb>
  );
};

export default Breadcrumb;
