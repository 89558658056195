import $RefParser from "json-schema-ref-parser";
import converter from "swagger2openapi";
import { v4 as uuidv4 } from 'uuid';
import { OpenAPIV3 } from "./Entity";

export interface OpenAPI extends OpenAPIV3.Document {
  swagger?: string;
}

export interface ParameterObject extends OpenAPIV3.ParameterObject {

}

export interface OperationObject extends OpenAPIV3.OperationObject {
  uuid: string;
  path: string;
  method: string;

}

export interface ProcessSpecResult extends Omit<OpenAPI, "paths">{
  paths: OperationObject[];
  securitySchemes?: {
    [key: string]: OpenAPIV3.SecuritySchemeObject;
  };
}

const ProcessSpec = (swagger: OpenAPI): Promise<ProcessSpecResult> => {
  let options = { patch: true, warnOnly: true };
  const openapi = !!swagger.swagger ? converter.convertObj(swagger, options)
    .then(function (api3Spec: {openapi: object}) {
      // console.log("api3Spec", api3Spec.openapi)
      console.info(
        "%c Convertion to OpenAPI 3.0 - Success !!! ",
        "color:cornflowerblue"
      );
      return ($RefParser as any).dereference(api3Spec.openapi);
    }) : Promise.resolve(swagger);

  return openapi
    .then(function (deReffedSpec: OpenAPI) {
      // console.log("deReffedSpec", deReffedSpec)
      console.info(
        "%c OpenAPI 3.0 Dereferencing - Success !!! ",
        "color:cornflowerblue"
      );
      let methods = [
        "get",
        "put",
        "post",
        "delete",
        "patch",
        "options",
        "head",
        "patch",
        "trace"
      ];
      let _paths: OperationObject[] = [];
      // For each path find the tag and push it into the corrosponding tag
      const { paths } = deReffedSpec;
      for (const path in paths) {
        const {parameters = [], summary="", description="", servers = [] } = paths[path];
        let commonParams = parameters;
        let commonPathProp = { servers, parameters,};
        Object.entries(deReffedSpec.paths[path]).filter(([key, value]) => methods.includes(key)).forEach(([methodName, value]) => {
          const fullPath = value as OpenAPIV3.OperationObject ;
            if (fullPath) {
              // Merge Common Parameters with This methods parameters
              let finalParameters = [];
              if (Array.isArray(fullPath.parameters)) {
                finalParameters = commonParams
                  .filter((commonParam) => {
                    return Array.isArray(fullPath.parameters) && !fullPath.parameters.some((param) => commonParam.name === param.name && commonParam.in === param.in);
                  })
                  .concat(fullPath.parameters);
              } else {
                finalParameters = commonParams.slice(0);
              }
              
              _paths.push({
                ...fullPath,
                uuid: uuidv4(),
                path,
                method: methodName,
                parameters: finalParameters,
                servers: fullPath.servers
                  ? commonPathProp.servers.concat(fullPath.servers)
                  : commonPathProp.servers,

              });
            }
        })

      }

      const securitySchemes = deReffedSpec.components ? deReffedSpec.components.securitySchemes : undefined;
      const result: ProcessSpecResult = {...deReffedSpec, paths: _paths, securitySchemes};
      return Promise.resolve(result);
    })
    .catch(function (err: Error) {
      console.error(err);
    });
}

export default ProcessSpec;
