import React, { useEffect, useState } from 'react';
import { Tabs, Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import styles from './index.module.scss';

const { TabPane } = Tabs;

const ALL = '$ALL$';

const getGroup = (data: any[]) => {
  const groups = data.map(i => i.group).filter(i => i);
  return Array.from(new Set(groups));
}

const getGroupCheckboxData = (data: any[], group: string) => {
  if (group === ALL) return data;
  return data.filter(i => i.group === group);
}

const getGroupKeys = (data: any[], group: string) => {
  return getGroupCheckboxData(data, group).map(i => i.value);
}

const getDefaultCheckedKeys = (data: any[]) => {
  return data.filter(i => i.checked).map(i => i.value);
}

interface BaseTabCheckboxProps {
  value?: CheckboxValueType[];
  onChange?: (data: any) => void;
  options: any[];
  hasShow?: boolean;
}

const BaseTabCheckbox: React.FC<BaseTabCheckboxProps> = (props) => {

  const { options = [], value = [], onChange, hasShow = false} = props;
  const [filterOptions, setFilterOptions] = useState<any[]>([]);

  const [activeKey, setActiveKey] = useState<string>(ALL);
  const [defaultCheckValue, ] = useState<CheckboxValueType[]>(getDefaultCheckedKeys(options));

  useEffect(() => {
    onChange && onChange(Array.from(new Set([...value, ...defaultCheckValue])))
  }, [defaultCheckValue]);

  useEffect(() => {
    if (hasShow) {
      const filter = options.filter(i => value.includes(i.value));
      setFilterOptions(filter);
    } else {
      setFilterOptions(options);
    }
  }, [options, value, hasShow])

  const group = getGroup(filterOptions);

  const localChange = (data: CheckboxValueType[]) => {
    const groupKeys = getGroupKeys(filterOptions, activeKey);
    const unaffectedKeys = value.filter(i => !groupKeys.includes(i));
    onChange && onChange([...data, ...unaffectedKeys]);
  }

  const buildCheckbox = (data: any[], group: string) => {
    const groupTreeData = getGroupCheckboxData(data, group);
    return (
      <Checkbox.Group style={{width: "100%"}} value={value} onChange={localChange}>
        <div className={styles.row}>
          {
            groupTreeData.map(i => (
              <div className={styles.col} key={i.value}>
                <Checkbox value={i.value} disabled={i.disabled || false}>{i.label}</Checkbox>
              </div>
            ))
          }
        </div>
      </Checkbox.Group>
    )
  }

  const buildShowCheckbox = (data: any[], group: string) => {
    const groupTreeData = getGroupCheckboxData(data, group);
    return (
      <div className={styles.row}>
        {
          groupTreeData.map(i => (
            <div className={styles.col} key={i.value}>
              <span>{i.label}</span>
            </div>
          ))
        }
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
       <Tabs tabPosition="left" activeKey={activeKey} onTabClick={(key) => {setActiveKey(key)}}>
          <TabPane tab="全部" key={ALL}>
            {
              hasShow ? buildShowCheckbox(filterOptions, ALL) : buildCheckbox(filterOptions, ALL)
            }
          </TabPane>
          {
            group.map(i => {
              return (
                <TabPane tab={i} key={i}>
                  {
                    hasShow ? buildShowCheckbox(filterOptions, i) : buildCheckbox(filterOptions, i)
                  }
                </TabPane>
              )
            })
          }
        </Tabs>
    </div>
  )
}

export default BaseTabCheckbox;