import { OpenAPIV3 } from './Entity';

/* Generates an HTML string containing type and constraint info */
export function getTypeInfo(schema: OpenAPIV3.SchemaObject, overrideAttributes:Pick<OpenAPIV3.BaseSchemaObject, "readOnly" | "writeOnly" | "deprecated"> = {}, inSingleLine = true){
    let html ="";
    const {
      type = "",
      enum: _enum,
      format,
      minimum,
      maximum,
      exclusiveMinimum,
      exclusiveMaximum,
      multipleOf,
      minLength,
      maxLength,
      readOnly,
      writeOnly,
      deprecated,
      pattern,
    } = schema;
    if (Array.isArray(_enum)){
      html += `enum:[${_enum.join(",")}]`;
    } else {
      html += type;
    }
    if(["integer", "number"].includes(type)) {
      if (minimum !== undefined && maximum !== undefined){
        html += ` (${exclusiveMinimum ? ">" : ""}${minimum} \u22ef ${exclusiveMaximum ? "<" : ""} ${maximum})`
      }
      else if (minimum!==undefined && maximum===undefined){
        html += ` (${exclusiveMinimum ? ">" : "\u2265"}${minimum})`
      }
      else if (minimum===undefined && maximum!==undefined){
        html += `(${exclusiveMaximum ? "<" : "\u2264"}${maximum})`
      }
      if (multipleOf!==undefined){
        html += ` (multiple of ${multipleOf})`
      }
    }

    if (type === "string"){
      if (minLength !== undefined  && maxLength !== undefined ){
        html += ` (${minLength} to ${maxLength} chars)`;
      }
      else if (minLength !== undefined  && maxLength === undefined ){
        html += ` (min:${minLength})`;
      }
      else if (minLength === undefined  && maxLength !== undefined ){
        html += ` (max:${maxLength})`;
      }
    }

    if(readOnly || overrideAttributes.readOnly){
      html += `${html} '🆁'`;
    }
    if(writeOnly || overrideAttributes.writeOnly){
      html += `${html} '🆆'`;
    }
    if(deprecated || overrideAttributes.deprecated){
      html += `${html} '❌'`;
    }

    let lineBreak = inSingleLine ? "" : "<br/>";
    if (format){
        html += ` ${lineBreak} (${format})`;    
    }
    if (pattern && !_enum){
        html += ` ${lineBreak}(${pattern})`;    
    }
    return html;
}

export type SchemaModel = {
  [propName: string]: SchemaModel;
} | SchemaModel[] | string;

/* For changing JSON-Schema to a Object Model that can be represnted in a tree-view */ 
export const schemaToModel = (schema: OpenAPIV3.SchemaObject, obj: {[propName: string]: SchemaModel} | SchemaModel[] = {}): SchemaModel => {
  const { type, properties = {}, allOf = [], description = "", readOnly, writeOnly, deprecated, required = [] } = schema;
  if (schema == null) {
      return "";
  }
  if(type === "object" && properties) {
    Object.keys(properties).forEach((key: string) => {
      !Array.isArray(obj) &&  (obj[required.includes(key) ? `${key}*` : key] = schemaToModel(properties[key], {}));
    })
  } else if (type === "array" && (schema as OpenAPIV3.ArraySchemaObject).items) {
    obj = [schemaToModel((schema as OpenAPIV3.ArraySchemaObject).items, {})];
  } else if (allOf.length > 0){
    if (allOf.length === 1){
      if (!allOf[0]){
        return `string~|~${description}`;
      } else{
        return `${ getTypeInfo(allOf[0],{readOnly, writeOnly, deprecated}) }~|~${description}`;
      }
    }

      // If allOf is an array of multiple elements, then they are the keys of an object
      let objWithAllProps = {};
      allOf.map(function(v){
        if (v && v.properties){
            let partialObj = schemaToModel(v, {});
            Object.assign(objWithAllProps, partialObj);
        }
      });
      obj = objWithAllProps;
  } else {
    return `${getTypeInfo(schema)}~|~${description}`;
  }
  return obj;
}

export const getDataType = (data: any) => {
  return Object.prototype.toString.call(data).slice(8, -1).toLowerCase();
};

export interface ShowExample{
  exampleType: string;
  exampleValue: any;
}

export const generateExample = (examples: OpenAPIV3.ParameterBaseObject['examples'], example: OpenAPIV3.ParameterBaseObject['example'], schema: OpenAPIV3.SchemaObject, outputType:string): ShowExample[] => {
  let finalExamples = [];
  if (examples) {
    for (let eg in examples) {
      let egJson = "";
      //TODO: in case the mimeType is XML then parse it as XML
      //egJson = JSON.parse(examples[eg].value);
      finalExamples.push({
        exampleType: "json",
        exampleValue:
          outputType === "text"
            ? JSON.stringify(examples[eg].value, undefined, 2)
            : examples[eg].value,
      });
    }
  } else if (example) {
    //TODO: in case the mimeType is XML then parse it as XML
    finalExamples.push({
      exampleType: "json",
      exampleValue:
        outputType === "text" ? JSON.stringify(example, undefined, 2) : example,
    });
  }

  if (finalExamples.length == 0) {
    // If schema examples are not provided then generate one from Schema (only JSON fomat)
    if (schema) {
      //TODO: in case the mimeType is XML then parse it as XML
      let egJson = schemaToObj(
        schema,
        {},
        { includeReadOnly: true, includeWriteOnly: true, deprecated: true }
      );
      finalExamples.push({
        exampleType: "json",
        exampleValue:
          outputType === "text" ? JSON.stringify(egJson, undefined, 2) : egJson,
      });
    } else {
      // No Example or Schema provided
      finalExamples.push({
        exampleType: "text",
        exampleValue: "",
      });
    }
  }
  return finalExamples;
}

interface Config {
  includeReadOnly?: boolean;
  includeWriteOnly?: boolean;
  deprecated?: boolean;
}

/* For changing JSON-Schema to a Sample Object, as per the schema */
const schemaToObj = (schema: OpenAPIV3.SchemaObject, obj: {[propName: string]: any} = {}, config: Config) => {
  if (schema == null) {
    return;
  }
  if (schema.type === "object" || schema.properties) {
    for (let key in schema.properties) {
      if (schema.properties[key].deprecated) {
        continue;
      }
      if (schema.properties[key].readOnly && !config.includeReadOnly) {
        continue;
      }
      if (schema.properties[key].writeOnly && !config.includeWriteOnly) {
        continue;
      }
      obj[key] = schemaToObj(schema.properties[key], {}, config);
    }
  } else if (schema.type === "array" && schema.items) {
    obj = [schemaToObj(schema.items, {}, config)];
  } else if (schema.allOf) {
    if (schema.allOf.length === 1) {
      if (!schema.allOf[0]) {
        return "string";
      } else {
        return getSampleValueByType(schema.allOf[0]);
      }
    }
    let objWithAllProps = {};
    schema.allOf.map(function (v) {
      if (v && v.type) {
        let partialObj = schemaToObj(v, {}, config);
        Object.assign(objWithAllProps, partialObj);
      }
    });
    obj = objWithAllProps;
  } else {
    return getSampleValueByType(schema);
  }
  return obj;
}

const getSampleValueByType = (schemaObj: OpenAPIV3.SchemaObject) => {
  if (schemaObj.example) {
    return schemaObj.example;
  }

  if (Object.keys(schemaObj).length === 0) {
    return null;
  }

  const typeValue = schemaObj.format || schemaObj.type || (schemaObj.enum ? "enum" : null);
  switch (typeValue) {
    case "int32":
    case "int64":
    case "integer":
      return 0;
    case "float":
    case "double":
    case "number":
      return 0.5;
    case "string":
      return schemaObj.enum
        ? schemaObj.enum[0]
        : schemaObj.pattern
        ? schemaObj.pattern
        : "string";
    case "byte":
      return btoa("string");
    case "binary":
      return "binary";
    case "boolean":
      return false;
    case "date":
      return new Date(0).toISOString().split("T")[0];
    case "date-time":
      return new Date(0).toISOString();
    case "dateTime":
      return new Date(0).toISOString();
    case "password":
      return "password";
    case "enum":
      return  schemaObj.enum ? schemaObj.enum[0] : '';
    case "uri":
      return "http://example.com";
    case "uuid":
      return "3fa85f64-5717-4562-b3fc-2c963f66afa6";
    case "email":
      return "user@example.com";
    case "hostname":
      return "example.com";
    case "ipv4":
      return "198.51.100.42";
    case "ipv6":
      return "2001:0db8:5b96:0000:0000:426f:8e17:642a";
    default:
      if (schemaObj.nullable) {
        return null;
      } else {
        console.warn("Unknown schema value", schemaObj);
        return "?";
      }
  }
}

