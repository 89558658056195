import React, { useEffect, useMemo, useState } from 'react';
import { Switch } from 'antd';

import { DataNode, TreeProps as AntdTreeProps } from 'antd/lib/tree';

import BaseTree, { filterAllKeys, getAllChildIds } from './BaseTree';

import styles from './index.module.scss';

function uniq (data: any[]) {
  return Array.from(new Set(data));
}

// 根据禁用的Ids数组重新构造新的具备禁用的DataNode数组
function buildDisabledTreeData (data: DataNode[], Ids: React.Key[]) {
  const res: DataNode[] = [];
  data.forEach(i => {
    res.push({
      ...i,
      disabled: Ids.includes(i.key) || (i.disabled ?? false),
      children: (i.children && i.children.length) ? buildDisabledTreeData(i.children, Ids) : undefined,
    })
  })
  return res;
}

// 根据勾选全部的Ids数组返回需要禁用的Key数组
function filterDisabledKeys (data: any[], Ids: React.Key[]) {
  const res: React.Key[] = [];
  data.forEach(i => {
    if (i.all && i.allId) {
      if (Ids.includes(i.allId)) {
        res.push(...filterAllKeys([i as DataNode]))
      } else if (i.children && i.children.length) {
        res.push(...filterDisabledKeys(i.children, Ids));
      }
    } else if (i.children && i.children.length) {
      res.push(...filterDisabledKeys(i.children, Ids));
    }
  })
  return res;
}

// 获取所有的具备全选的全选Id数组
function getHasAllIds (data: any[]) {
  const res: React.Key[] = [];
  data.forEach(i => {
    if (i.all) {
      res.push(i.allId)
    }
    if (i.children && i.children.length) {
      res.push(...getHasAllIds(i.children));
    }
  })
  return res;
}


const filterIdsTreeData = (data: DataNode[], keys: React.Key[]) => {
  const res: DataNode[] = [];
  data.forEach(i => {
    if (keys.includes(i.key)) {
      res.push(i)
    } else {
      if (i.children && i.children.length) {
        const child = filterIdsTreeData(i.children, keys);
        if (child.length) {
          const newItem = {
            ...i,
            children: child,
          }
          res.push(newItem)
        }
      }
    }
  })
  return res;
}

interface TreeProps extends AntdTreeProps {
  value?: React.Key[];
  onChange?: (data: any) => void;
  hasSearch?: boolean;
  hasShow?: boolean;
  searchPlaceholder?: string;
}

const Tree: React.FC<TreeProps> = (props) => {

  const { value, onChange, hasSearch, searchPlaceholder, treeData = [], hasShow = false } = props;

  // switch的开启选项
  const [switchKeys, setSwitchKeys] = useState<React.Key[]>([]);

  const [disabledKeys, setDisabledKeys] = useState<React.Key[]>([]);

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  const hasAllIds = useMemo(() => {
    return getHasAllIds(treeData);
  }, [treeData]);

  const allChildId = useMemo(() => getAllChildIds(treeData), [treeData]);

  useEffect(() => {
    if (hasShow) {
      setExpandedKeys(filterAllKeys(treeData));
    }
  }, [hasShow, treeData])

  useEffect(() => {
    const filterSwitchKeys = (value ?? []).filter(i => hasAllIds.includes(i));
    const disabledKeys = filterDisabledKeys(treeData, filterSwitchKeys);
    setSwitchKeys(uniq([...filterSwitchKeys]));
    setDisabledKeys(uniq([...disabledKeys]));
  }, [value, treeData, hasAllIds])

  const onClick = (res: boolean, node: any) => {
    // console.log(res, node)
    const ids = filterAllKeys([node]);
    let newSwitchKeys: React.Key[];
    let newDisabledKeys: React.Key[];
    if (res) {
      newSwitchKeys = uniq([...switchKeys, node.allId]);
      newDisabledKeys = uniq([...disabledKeys, ...ids]);
    } else {
      newSwitchKeys = switchKeys.filter(i => i !== node.allId);
      newDisabledKeys = disabledKeys.filter(i => !ids.includes(i));
    }
    // TODO:这里需要更改一下
    const newValue = (value ?? []).filter(i => !newDisabledKeys.includes(i)).filter(i => !hasAllIds.includes(i)).filter(i => allChildId.includes(i));
    const resValue = buildValue(newValue, newDisabledKeys, newSwitchKeys);
    onChange && onChange(resValue);
  }

  const buildNewValue = useMemo(() => {
    const removeAllIdValue = (value ?? []).filter(i => !switchKeys.includes(i));
    const filterChildIds = removeAllIdValue.filter(i => allChildId.includes(i))
    return uniq([...filterChildIds, ...disabledKeys]);
  }, [value, disabledKeys, switchKeys, allChildId]);

  const filterTreeData = useMemo(() => {
    if (hasShow) {
      return filterIdsTreeData(treeData, buildNewValue)
    }
    return buildDisabledTreeData(treeData, disabledKeys)
  }, [treeData, disabledKeys, hasShow, buildNewValue]);

  const titleRender = (node: any) => {
    if (((node.all && (!node.children || (node.children && !node.children.length))) || (node.all && expandedKeys.includes(node.key))) && !hasShow) {
      return (
        <div className={styles.customTreeRender}>
          {node.title}
          <Switch
            onClick={(res, e) => {e.stopPropagation(); onClick(res, node)}}
            checked={switchKeys.includes(node.allId)}
            size="small"
            className={styles.switch}
          />
          <span>全部</span>
        </div>
      )
    }
    return node.title;
  }

  const buildValue = (value: React.Key[], localDisabledKeys: React.Key[], allSwitchKeys: React.Key[]) => {
    const newKeys = uniq([...value, ...localDisabledKeys]);
    const effectKeys = filterAllKeys(filterIdsTreeData(treeData, newKeys))
    const newRes = effectKeys.filter(i => !localDisabledKeys.includes(i)).filter(i => !hasAllIds.includes(i));
    return uniq([...newRes, ...allSwitchKeys]);
  }

  const localChange = (e: any) => {
    // TODO: 这里获取涉及的树节点
    // 这里是涉及的所有子节点
    const res = buildValue(e as React.Key[], disabledKeys, switchKeys);
    onChange && onChange(res);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.formItem}>
        <BaseTree 
          hasSearch={hasSearch && !hasShow}
          treeData={filterTreeData}
          checkable={!hasShow}
          selectable={false}
          checkedKeys={buildNewValue}
          onCheck={localChange}
          titleRender={titleRender}
          onExpand={(expandedKeys) => {setExpandedKeys(expandedKeys)}}
          expandedKeys={expandedKeys}
          searchPlaceholder={searchPlaceholder}
        />
      </div>
    </div>
  )
}

export default Tree;