import React, {useCallback, useEffect, useState} from "react";
import {Button, Input, Radio} from 'antd';
import Breadcrumb from "../../../../open-platform-commons/components/Breadcrumb";
import styles from "./index.module.scss";
import Header from "../../../../open-platform-commons/components/TableModule/Header";
import {Table} from "../../../../open-platform-commons/components/TableModule";
import {Paging, usePaging} from "@maxtropy/central-commons-ui/es/components";
import {Link} from "react-router-dom";
import {DeveloperListRequest, DeveloperListResponse, getDeveloperList} from "../../../api/developer";
import TooltipText from "../../../../open-platform-commons/components/TooltipText";
import {adCodeToName} from "../../../../open-platform-commons/utils/adcode";

const breadcrumbRoutes = [
  {breadcrumbName: '首页'},
  {breadcrumbName: '开发者管理'},
];

enum RadioValue {
  TOTAL = '',
  NOCOMPLETED = 'false',
}

const columns = [{
  title: '客户名称',
  dataIndex: 'name',
  ellipsis: {showTitle: true},
  render: (record: string) => <TooltipText text={record} />
}, {
  title: '客户完整名称',
  dataIndex: 'fullName',
  ellipsis: {showTitle: true},
  render: (record: string) => <TooltipText text={record} />
}, {
  title: '客户地址',
  ellipsis: {showTitle: true},
  render: (record: DeveloperListResponse) =>
    <TooltipText text={`${record.adCode && adCodeToName(record.adCode)}`} />
}, {
  title: '账号',
  width: 150,
  dataIndex: 'username',
  render: (record: string) => <TooltipText text={record} />
}, {
  title: '负责人',
  width: 110,
  dataIndex: 'contactName',
  ellipsis: {showTitle: true},
  render: (record: string) => <TooltipText text={record} />
}, {
  title: '负责人手机',
  width: 140,
  dataIndex: 'contactPhone',
  ellipsis: {showTitle: true},
  render: (record: string) => <TooltipText text={record} />
}, {
  title: '操作',
  width: 140,
  render: (record: DeveloperListResponse) => (
    <div className={styles.action}>
      <Link to={`/operator/manage/detail/${record.mcid}`}>查看</Link>
      <Button type='link' href={`/operator/application/${record.id}`}
              disabled={!record.id} className={styles.btn}>应用列表</Button>
    </div>
  )
}];

const ManageList: React.FC = () => {

  const [badge, setBadge] = useState<number>();
  const [data, setData] = useState<DeveloperListResponse[]>([]);
  const [searchParams, setSearchParams] = useState<DeveloperListRequest>({});
  const [loading, setLoading] = useState<boolean>(false);
  const pagingInfo = usePaging();
  const {pageOffset, pageSize, setTotalCount, setPageOffset} = pagingInfo;

  const initRadioRequest = useCallback(async () => {
    const response = await getDeveloperList({...searchParams, auth_completed: false});
    setBadge(response.total);
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      await initRadioRequest()
    })().catch(e => console.error(e))
  }, [initRadioRequest]);

  useEffect(() => {
    setLoading(true);
    getDeveloperList({
      ...searchParams,
      count: pageSize,
      offset: pageOffset,
    }).then((data: any)=> {
      setTotalCount(data.total);
      setData(data.contents);
      setLoading(false);
    })
  }, [pageOffset, pageSize, searchParams, setTotalCount]);

  const leftContent = (
    <Radio.Group options={[
      {label: '全部', value: RadioValue.TOTAL},
      {label: `未完善${badge ? `(${badge})` : ''}`, value: RadioValue.NOCOMPLETED},
    ]}
       value={searchParams.auth_completed}
       defaultValue={RadioValue.TOTAL}
       optionType="button"
       className={styles.radio}
       onChange={(v) => {
         setSearchParams({...searchParams, auth_completed: v.target.value});
         setPageOffset(0);
       }}
    />
  );

  const rightContent = (
    <Input.Search placeholder='请输入客户名称' style={{width: 200}}
                  onSearch={value => {
                    setSearchParams({...searchParams, name: value});
                    setPageOffset(0)
                  }} />
  );

  return (
    <>
      <Breadcrumb routes={breadcrumbRoutes} />
      <div className={styles.container}>
        <Header title='开发者管理'>
          <div className={styles.search}>
            <div>{leftContent}</div>
            <div>{rightContent}</div>
          </div>
        </Header>
        <Table columns={columns}
               dataSource={data}
               loading={loading}
               rowClassName={record => {
                  if (!record.authCompleted) {
                    return styles.backgroundRed
                  }
                  return ''
                }} />
        <Paging pagingInfo={pagingInfo}  className={styles.pagination} />
      </div>
    </>
  )
};

export default ManageList
