import React, { useContext, useMemo, useRef, useState } from 'react';
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import { JsonTreeContext } from './JsonTreeContent';

interface ContentBlockProps {
  parentDataType: String;
  dataType: String; // Current text data type
  text: String;
  isLastKey: Boolean;
  currentKey?: String;
}

const cx = classNames.bind(styles);

const ContentBlock: React.FC<ContentBlockProps> = ({ parentDataType, dataType, text, isLastKey,  currentKey }) => {

  const { format } = useContext(JsonTreeContext);

  const description = useMemo(() => {
    if (format === "text") {
      let temp = text.split("~|~");
      return temp[1] ? temp[1] : "";
    }
    return "";
  }, [format, text]);

  const [descrClass, setDescrClass ]= useState("sw-descr-collapsed");

  const getText = () => {
    if (format==='text'){
      return text.split("~|~")[0];
    }
    let output = text;
    if (dataType === 'string') output = `"${output}"`;
    if (isLastKey) output += ','
    return output;
  }

  const handleItemToggleDescr = () => {
    if (descrClass ==="sw-descr-collapsed"){
      setDescrClass("sw-descr-expanded");
    }
    else{
      setDescrClass("sw-descr-collapsed");
    }
  }

  return (
    <div className={cx("sw-content-block")}>
      {parentDataType === "object" ? (
        <div className={cx("sw-content-key")}>
          {currentKey?.endsWith("*") ? (
            <span className={cx("item-required")}>
              {currentKey.substring(0, currentKey.length - 1)}
            </span>
          ) : (
            currentKey
          )}
          :
        </div>
      ) : undefined}
      <div
        className={cx(
          "sw-content",
          `sw-datatype-${format === "json" ? dataType : text.substring(0, 4)}`
        )}
      >
        {getText()}
      </div>
      <div style={{ flex: 1, minWidth: "15px" }}></div>
      <div className={cx(`sw-descr ${descrClass}`)}>{description}</div>
      {/* {description ? (
        <span
          className={cx("sw-descr-expander")}
          onClick={handleItemToggleDescr}
        >
          {descrClass === "sw-descr-collapsed" ? "\u2935" : "\u2934"}
        </span>
      ) : undefined} */}
    </div>
  );
};

export default ContentBlock;
