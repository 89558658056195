import React, { forwardRef, useState, useImperativeHandle, useCallback, useEffect } from 'react';
import { Input, TreeSelect as AntTreeSelect } from 'antd';
import { TreeSelectProps as AntTreeSelectProps } from 'antd/lib/tree-select';
import { DataNode } from 'antd/lib/tree';

import styles from './index.module.scss';

const { Search } = Input;

const getAllChildKey = (data: DataNode[]) => {
  const res: React.Key[] = [];
  data.forEach(i => {
    if (i.children && i.children.length) {
      res.push(...getAllChildKey(i.children))
    } else {
      res.push(i.key)
    }
  })
  return res;
}

interface TreeSelectProps<T> extends Omit<AntTreeSelectProps<T>, 'onChange'> {
  hasSearch?: boolean;
  onChange?: (data: React.Key[]) => void;
  placeholder?: string;
  searchPlaceholder?: string;
}

export interface TreeSelectRef {
  getonBlurDisable: () => boolean;
}

const TreeSelect: React.ForwardRefRenderFunction<TreeSelectRef, TreeSelectProps<React.Key[]>> = (props, ref) => {
  const {hasSearch = true, treeData, value, onChange, searchPlaceholder, ...restProps} = props;
  const [onBlur_disable, setOnBlurDisable] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const allChildKeys = treeData ? getAllChildKey(treeData as DataNode[]) : [];
    const newValue = (value ?? []).filter(i => allChildKeys.includes(i));
    if (newValue.length !== (value ?? []).length) {
      onChange && onChange(newValue);
    }
  }, [treeData]);

  useImperativeHandle(ref, () => ({
    getonBlurDisable: () => onBlur_disable,
  }));

  const dropdownRender = useCallback((menu: React.ReactElement) => {
    if (hasSearch) {
      return (
        <div
          onMouseEnter={() => setOnBlurDisable(true)}
          onMouseLeave={() => setOnBlurDisable(false)}
        >
          <Search
            className={styles.search}
            placeholder={searchPlaceholder}
            onKeyDown={(e) => {e.stopPropagation()}} 
            onChange={(e) => {
              if (!e.target.value) {
                setSearch('');
              }
            }}
            onSearch={(value) => {setSearch(value)}}
          />
          {menu}
        </div>
      )
    } else {
      return menu
    }
  }, [hasSearch]);
  

  return (
    <AntTreeSelect
      searchValue={search}
      dropdownRender={dropdownRender}
      className={styles.treeSelect}
      treeData={treeData}
      value={value}
      onChange={onChange}
      {...restProps}
    />
  )
}

export default forwardRef(TreeSelect);