import React, { useMemo, useState, Key, useCallback, useEffect } from 'react';
import { Input, Tree as AntdTree, Space, Empty } from 'antd';
import { DataNode, TreeProps as AntdTreeProps } from 'antd/lib/tree';

const { Search } = Input;

// 获取树中的所有的子节点
export function getAllChildIds (data: DataNode[]) {
  const res: React.Key[] = [];
  data.forEach(i => {
    if (i.children && i.children.length) {
      res.push(...getAllChildIds(i.children));
    } else {
      res.push(i.key);
    }
  })
  return res;
}

// 搜索过滤
const filterSearchTreeData = (data: DataNode[], searchValue: string) => {
  const res: DataNode[] = [];
  data.forEach(i => {
    if ((i.title as string).includes(searchValue)) {
      res.push(i)
    } else {
      if (i.children && i.children.length) {
        const child = filterSearchTreeData(i.children, searchValue);
        if (child.length) {
          const newItem = {
            ...i,
            children: child,
          }
          res.push(newItem)
        }
      }
    }
  })
  return res;
}


// 获取树的所有key
export const filterAllKeys = (data: DataNode[]) => {
  const res: React.Key[] = [];
  data.forEach(i => {
    res.push(i.key);
    if (i.children) {
      res.push(...filterAllKeys(i.children));
    }
  })
  return res;
}

const getUnaffectedKeys = (searchAllKeys: React.Key[], checkKeys: React.Key[]) => {
  return checkKeys.filter(i => !searchAllKeys.includes(i))
}

interface TreeProps extends AntdTreeProps {
  hasSearch?: boolean;
  searchPlaceholder?: string;
}

const BaseTree: React.FC<TreeProps> = (props) => {

  const [search, setSearch] = useState<string>('');
  const { treeData = [], checkedKeys = [], onCheck, hasSearch = true, searchPlaceholder, expandedKeys, onExpand, ...resProps } = props;

  // 手动点击的展开的树数组
  // const [handleExpandedKeys, setHandleExpandedKeys] = useState<Key[]>([]);

  const [localExpandedKeys, setLocalExpandedKeys] = useState<Key[]>([]);

  const allChildKeys = useMemo(() => {
    return getAllChildIds(treeData)
  }, [treeData])

  // const allTreeKeys = useMemo(() => filterAllKeys(treeData), [treeData]);

  const serchTreeData = useMemo(() => {
    if (search) {
      return filterSearchTreeData(treeData, search)
    } else {
      return treeData;
    }
  }, [treeData, search])

  useEffect(() => {
    if (search) {
      setLocalExpandedKeys(expandedKeys ?? []);
      localExpand(filterAllKeys(serchTreeData), {}) 
    } else {
      if (localExpandedKeys.length) {
        localExpand(localExpandedKeys, {}) 
      }
    }
    
  }, [serchTreeData])

  const localExpand = useCallback((keys: Key[], info: any) => {
    onExpand && onExpand(keys, info)
  }, [onExpand])

  const searchTreeKeys = useMemo(() => getAllChildIds(serchTreeData), [serchTreeData]);

  const unaffectedKeys = useMemo(() => getUnaffectedKeys(searchTreeKeys, checkedKeys as React.Key[]), [searchTreeKeys, checkedKeys]);

  const localCheck = (checkKeys: React.Key[], info: any) => {
    const filterKeys = checkKeys.filter(i => allChildKeys.includes(i));
    onCheck && onCheck([...filterKeys, ...unaffectedKeys], info);
  }

  return (
    <Space direction="vertical" style={{width: '100%'}} size={30}>
      {
        hasSearch && <Search onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault()
          }
        }}
        onSearch={(e) => {setSearch(e)}}
        placeholder={searchPlaceholder}
        onChange={(e) => {
          if (!e.target.value) {
            setSearch('');
          }
        }}
      />
      }
      {
        serchTreeData.length !== 0 ? (
      <AntdTree
        onCheck={(e, info) => {localCheck(e as React.Key[], info)}}
        treeData={serchTreeData}
        checkedKeys={checkedKeys}
        expandedKeys={expandedKeys}
        onExpand={localExpand}
        {...resProps}
      />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
    </Space>
  )
}

export default BaseTree;