import {fetch} from "@maxtropy/central-commons-ui";
import {OpenAPI} from "../../developer/pages/InterfaceMain/swagger-parse";

export interface UpstreamServiceListItem {
  upstreamServiceIdentifier: string;
  upstreamServiceName: string;
}

export function queryUpstreamServiceList(): Promise<UpstreamServiceListItem[]> {
  return fetch(`/api/upstreamService/list`);
}

export function queryUpstreamServiceListByDeveloperId(developerId?: string): Promise<UpstreamServiceListItem[]> {
  return fetch(`/api/upstreamService/getUpstreamsByDeveloperId?developerId=${developerId}`);
}

export interface QueryUpstreamServiceInfoRequest {
  upstreamServiceIdentifier: string;
}

export function queryUpstreamServiceInfo({upstreamServiceIdentifier}: QueryUpstreamServiceInfoRequest): Promise<OpenAPI> {
  return fetch(`/api/upstreamService/${upstreamServiceIdentifier}/openApi`);
}
