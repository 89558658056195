import React, {useCallback} from "react";
import {Layout, Menu, Dropdown} from "antd";
import {Link, useLocation} from "react-router-dom"
import styles from "./styles.module.scss"
import logoImg from "../../assets/images/logo.png";
import titleDeveloper from "../../assets/images/title-developer.png";
import titleOperation from "../../assets/images/title-operation.png";
import {DownOutlined, LogoutOutlined} from "@ant-design/icons"
import {getDeveloper, getOperator, User} from "../../api/developerUser";
import {useAsync} from "@maxtropy/central-commons-ui";

const {Header: AntDHeader} = Layout;

function getSelectedMenuKey(pathname: string, menuData: MenuData[]) {
  const countPathMatchLeft = (url: string) => pathname.replace(url, '').length;
  return menuData
    .map(menu => ({...menu, left: countPathMatchLeft(menu.url)}))
    .filter(res => res.left === Math.min(...menuData.map(item => countPathMatchLeft(item.url))))
    .map(val => val.key)
}

export enum PlatformType {
  OPERATION,
  DEVELOPER
}

interface MenuData {
  key: string;
  name: string;
  url: string
}

interface HeaderProps {
  type?: PlatformType;
  menuData?: MenuData[]
}

const dropMenu = (
  <Menu className={styles.dropMenu}>
    <Menu.Item key="exit" className={styles.dropMenuItem}>
      <a href={'/server/logout'}><LogoutOutlined/><span className={styles.text}>退出</span></a>
    </Menu.Item>
  </Menu>
);

export const Header: React.FC<HeaderProps> = (props) => {

  const {type, menuData} = props;
  const title = type === PlatformType.DEVELOPER ? titleDeveloper : titleOperation;
  const {pathname} = useLocation();

  const getUser: () => Promise<User | undefined> = useCallback(async () => {
    switch (type) {
      case PlatformType.DEVELOPER:
        return await getDeveloper();
      case PlatformType.OPERATION:
        return await getOperator();
      default:
        return undefined
    }
  }, [type]);

  const user = useAsync(getUser);

  return (
    <AntDHeader className={styles.header}>
      <div className={styles.logo}>
        <img src={logoImg} className={styles.logoImg} alt={'img'}/>
        {type !== undefined && <img src={title} className={styles.title} alt={'title'}/>}
      </div>
      <Menu className={styles.menu} theme="dark" mode="horizontal"
            selectedKeys={getSelectedMenuKey(pathname, (menuData ?? []))}>
        {(menuData ?? []).map(menu => (
          <Menu.Item key={menu.key} className={styles.menuItem}>
            <Link to={menu.url}>
              {menu.name}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
      <Dropdown overlay={dropMenu} trigger={['click']}>
        <span className={styles.userName}>
          {user?.name}&nbsp;&nbsp;
          <DownOutlined/>
        </span>
      </Dropdown>
    </AntDHeader>
  )
};
