import qs from "qs";
import {fetch, Paged, PageRequest} from "@maxtropy/central-commons-ui";
import { AuthorityDynamicFormResponse } from '../../open-platform-commons/api/entity';

export interface ApplicationList {
  appKey?: string;
  authorityJson?: string;
  id?: number;
  institutionName?: string;
  mcid?: string;
  memo?: string;
  name?: string;
}

export interface GetApplicationRequest extends PageRequest{
  byName?: string
}

export function getApplicationList(query: GetApplicationRequest): Promise<Paged<ApplicationList>> {
  return fetch(`/api/application/list?${qs.stringify(query, {indices: false})}`)
}

export function deleteApplication(id?: number) {
  return fetch(`/api/application/${id}`, {
    method: 'DELETE'
  })
}
export interface QueryApplicationAllRequest extends PageRequest{
  developerId?: number;
  byName?: string;
}

export interface OperationApplication {
  authorityJson: string;
  contactName: string;
  contactPhone: string;
  customerName: string;
  developerId: number;
  id: string;
  institutionName: string;
  memo: string;
  name: string;
  upstreamService: string;
  username: string;
}


export function queryApplicationAll(query: QueryApplicationAllRequest): Promise<Paged<OperationApplication>> {
  return fetch('/api/application/listAll?'+qs.stringify(query, {indices: false}));
}

// 运维侧和开发者侧
export function getAppAuthorityDynamicForm(params: any): Promise<AuthorityDynamicFormResponse> {
  return fetch(`/api/application/readApplicationAuthorityFormConfig`, {
    method: 'POST',
    body: JSON.stringify(params),
  })
}

export interface AppRequest {
  upstreamServiceIdentifier: string;
  authorityJson: string;
  memo: string;
  institutionName: string;
  name: string;
}

export function createApp(params: AppRequest): Promise<number> {
  return fetch(`/api/application/create`, {
    method: 'POST',
    body: JSON.stringify(params),
  })
}

export function updateApp(appId: string, params: AppRequest) {
  return fetch(`/api/application/${appId}/update`, {
    method: 'PUT',
    body: JSON.stringify(params),
  })
}

export interface AppDataProps {
  id: number;
  name: string;
  institutionName?: string;
  appKey: string;
  developerId: number;
  upstreamServiceIdentifier: string;
  memo?: string;
  authorityJson: string;

}

// 运维侧和开发者侧都使用
export function getAppInfo(appId: string): Promise<AppDataProps> {
  return fetch(`/api/application/${appId}/info`);
}

export interface SecretProps {
  appKey: string;
  appSecret: string;
}

export function generateSecret(appId: string): Promise<SecretProps> {
  return fetch(`/api/application/${appId}/generateSecret`, {
    method: "POST"
  });
}
