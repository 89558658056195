import React, {PropsWithChildren} from "react"
import {Header, PlatformType} from "./Header";
import {useSSOAuth} from "@maxtropy/central-commons-ui";

interface DeveloperLayoutProps {

}

const DEVELOPER_MENU_DATA = [
  {name: "控制台", url: "/developer", key: "console"},
  {name: "接口文档", url: "/developer/document", key: "document"}
];

const DeveloperLayout: React.FC<PropsWithChildren<DeveloperLayoutProps>> = ({children}) => {
  const authState = useSSOAuth(true, true);

  return (
    <div key={authState}>
      <Header type={PlatformType.DEVELOPER} menuData={DEVELOPER_MENU_DATA}/>
      {children}
    </div>
  )
};

export default DeveloperLayout;
